import { createSlice } from "@reduxjs/toolkit";

export const dialogStateSlice = createSlice({
  name: "Dialog",
  initialState: {
    collectionLockedDialog: {
      open: false,
      message: "",
      contractAddress: "",
    },
  },
  reducers: {
    openCollectionLockedDialog: (state, action) => {
      state.collectionLockedDialog = action.payload;
    },
  },
});

export const { openCollectionLockedDialog } = dialogStateSlice.actions;

export default dialogStateSlice.reducer;
