import { responsiveFontSizes, createTheme } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "#00419E",
      light: "#0b63e0",
    },
    secondary: {
      main: "#D6EDF6",
    },
    text: {
      textColortheme: "#fff",
      gray: "#DCDCDC ",
    },
    background: {
      basic: "#13141E",
      secondary: "#fff",
      darkBlue: " #131923",
    },
  },
  typography: {
    fontFamily: ['"Anton"', "Open Sans", "sans-serif"].join(","),

    h1: {
      fontSize: "36px",
      fontWeight: "400",
      fontFamily: "Anton",
    },
    h2: {
      fontSize: "24px",
      fontWeight: "400",
      fontFamily: "Anton",
    },
    h3: {
      fontSize: "26px",
      fontWeight: "400",
      fontFamily: "Anton",
    },
    h4: {
      fontSize: "28px",
      fontWeight: "400",
      fontFamily: "Anton",
    },
    subtypo: {
      color: "rgba(220, 220, 220, 0.69)",
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: "400",
    },
    subtypo2: {
      color: "#fff",
      fontSize: "14px",
      fontWeight: "400",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    subtypo3: {
      fontFamily: "Open Sans",
      color: "#fff",
      fontSize: "14px",
      fontWeight: "400",
    },
    subtypo4: {
      fontFamily: "Roboto Condensed",
      color: "#fff",
      fontSize: "14px",
    },
    subtitle1: {
      fontSize: "18px",
      fontFamily: "Anton",
      background: "linear-gradient(231.13deg, #08A5CF 14.71%, #2FEF64 87.56%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
    },
    subtitle2: {
      fontSize: "24px",
      fontFamily: "Anton",
      background: "linear-gradient(231.13deg, #08A5CF 14.71%, #2FEF64 87.56%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
    },
    subtitle3: {
      fontSize: "36px",
      fontFamily: "Anton",
      background: "linear-gradient(231.13deg, #08A5CF 14.71%, #2FEF64 87.56%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
    },
    body1: {
      fontSize: "18px",
      fontFamily: "Anton",
      color: "rgba(217, 217, 217, 0.69)",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "gradient" },
          style: {
            background:
              "linear-gradient(231.13deg, #08A5CF 14.71%, #2FEF64 87.56%)",
            color: "#000",
            borderRadius: "10px",
            fontStyle: "normal",
            fontFamily: "Open Sans",
            fontWeight: "700",
            fontSize: "18px",
            "&:hover": {
              background:
                "linear-gradient(231.13deg, #08A5CF 14.71%, #2FEF64 87.56%)",
            },
          },
        },
        {
          props: { variant: "gradienttext" },
          style: {
            overflow: "hidden",
            textTransform: "uppercase",
            background:
              "linear-gradient(231.13deg, #08A5CF 14.71%, #2FEF64 87.56%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
            fontSize: "16px",
            "&:hover": {
              background: "#000",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
            },
          },
        },
      ],
    },
  },
});

theme = responsiveFontSizes(theme);
export { theme };
