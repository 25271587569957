import { api } from "./api";

export const web3ProductApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getSingleWeb3Product: builder.query({
      query: (id) => {
        return {
          url: `/find-user-web3-product/${id}`,
          method: "GET",
        };
      },
      providesTags: ["getSingleWeb3Product"],
    }),
  }),
});
export const { useGetSingleWeb3ProductQuery } = web3ProductApi;
