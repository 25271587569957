/* eslint-disable array-callback-return */
import { createSlice } from "@reduxjs/toolkit";
const initialState = JSON.parse(localStorage.getItem("cartitem"))
  ? JSON.parse(localStorage.getItem("cartitem"))
  : [];

function SetlocalStoarge(state) {
  localStorage.setItem("cartitem", JSON.stringify(state));
}

export const addToCart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addProduct: (state, action) => {
      // const findState = state?.filter((info) => {
      //   if (info?._id === action?.payload?._id) {
      //     return info;
      //   }
      // });
      // if (findState?.length > 0) {
      //   state.map((info, i) => {
      //     if (info?._id === action?.payload?._id) {
      //       state[i].quantity += action?.payload?.quantity;
      //       state[i].imgId = action?.payload?.imgId ?? "";
      //     }
      //   });
      // } else {
      state.push(action.payload);
      // }
      SetlocalStoarge(state);
    },
    deleteProduct: (state, action) => {
      state.splice(
        state.findIndex((item) => item._id === action.payload),
        1
      );
      SetlocalStoarge(state);
    },

    clear: (state) => (state = []),
    reset: (state) => {
      state = [];
      localStorage.removeItem("cartitem");
    },
  },
});

export const { addProduct, deleteProduct, reset, clear } = addToCart.actions;
