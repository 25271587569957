import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";
import { gradientButton } from "./AppComponents";

export const BackButton = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <Button
        onClick={() => navigate(-1)}
        sx={{
          height: "35px",
          px: 2,
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "Open Sans",
          width: "85px",
          fontSize: "14px",
          fontWeight: "700",
          borderRadius: "8px",
          color: "#dcdcdc",
          border: "1px transparent",
          backgroundImage:
            " linear-gradient(#121B25, #121B25  ),linear-gradient(37.34deg, rgba(72, 77, 82, 0.75) 4.36%, rgba(142, 146, 151, 0.75) 48.4%, rgba(111, 123, 136, 0.75) 96.11%)",
          backgroundOrigin: "border-box",
          backgroundClip: "content-box, border-box",
          padding: "2px",
          "&:hover": {
            backgroundImage:
              " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
          },
          ".MuiButton-outlined": {
            border: "none",
          },
        }}
      >
        <KeyboardBackspace
          style={{
            marginRight: "5px",
            fontSize: "23px",
            color: " #dcdcdc",
          }}
        />
        Back
      </Button> */}
      <Box
        sx={{
          ...gradientButton,
          cursor: "pointer",
          width: "85px",
          height: "35px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
        }}
      >
        <Button
          variant="gradienttext"
          onClick={() => navigate(-1)}
          sx={{
            fontFamily: "Open Sans",
            fontSize: "14px",
            fontWeight: "700",
            color: " #dcdcdc",
            "&:hover": {
              color: "#000",
            },
          }}
        >
          <KeyboardBackspace
            sx={{
              marginRight: "5px",
              fontSize: "23px",
            }}
          />{" "}
          Back
        </Button>
      </Box>
    </>
  );
};
