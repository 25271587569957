import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  styled,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { DeleteForever } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";

import { deleteProduct } from "../slice/slice";
import { gradientButton, ToastNotify } from "./SmallComponents/AppComponents";
import { useCheckQuantityApiMutation } from "../services/orderApi";
import Loading from "./SmallComponents/loading";
export const CustomTextField = styled(TextField)({
  background: "#121822",
  border: "3px solid rgba(82, 89, 96, 0.26)",
  borderRadius: "18px",
  padding: "7px 24px",
  height: "62px",
  color: "#d9d9d9",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 0",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiInputBase-input": {
    color: "rgba(217, 217, 217, 0.35);",
    fontSize: "18px",
  },
});
const CartItem = ({ value, setTabBtnValue, account }) => {
  const matches = useMediaQuery("(max-width:960px)");
  const dispatch = useDispatch();
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [checkProductQuantityApi] = useCheckQuantityApiMutation();

  // error toast function
  const toastShowing = (displayMessage) => {
    setAlertState({
      open: true,
      message: displayMessage,
      severity: "error",
    });
  };

  const cart = useSelector((store) => store.cart);
  // console.log(cart, "cart");
  const proceedToCheckout = async () => {
    try {
      // if (!account) {
      //   return toastShowing("Please connect your wallet to continue");
      // }
      if (cart?.length !== 0) {
        setLoading(true);
        for (let x of cart) {
          const { _id } = x;
          const { error } = await checkProductQuantityApi({
            id: _id,
          });
          if (error) {
            setLoading(false);
            return toastShowing(error?.data?.message);
          }
        }
      }
      setTabBtnValue(value + 1);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const removeItem = (id) => {
    dispatch(deleteProduct(id));
  };
  return (
    <Box>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <Typography variant="h1" mt={5}>
        My Cart ({cart?.length})
      </Typography>
      <Grid container spacing={2} alignContent="center">
        <Grid
          item
          xs={12}
          md={8}
          pb={4}
          sx={{
            borderBottom: { xs: "3px solid #262E34", md: "none" },
          }}
        >
          {cart?.length > 0 ? (
            cart?.map(({ name, _id, quantity, price, images }, index) => {
              return (
                <Box
                  key={index}
                  mt={4}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={{ xs: "5px", md: "20px" }}
                >
                  <IconButton
                    onClick={() => removeItem(_id)}
                    sx={{
                      display: "block",
                    }}
                  >
                    <Tooltip
                      title="Delete"
                      sx={{
                        color: "#DB2929",
                        fontSize: { xs: "20px", md: "25px" },
                      }}
                    >
                      <DeleteForever
                        sx={{
                          color: "#DB2929",
                          fontSize: "25px",
                        }}
                      />
                    </Tooltip>
                  </IconButton>

                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      border: "2px solid #4A4F56",
                      p: matches ? 1 : 2,
                      borderRadius: matches ? "10px" : "18px",
                    }}
                  >
                    <img
                      src={`${images[0]}`}
                      style={{
                        width: matches ? "50px" : "100px",
                        height: matches ? "50px" : "100px",
                        borderRadius: "6px",
                      }}
                      alt="bag"
                    />
                  </Box>

                  <Box width={{ md: "50%", xs: "50%" }} mt={{ xs: 1.5, md: 0 }}>
                    <Typography
                      variant="h3"
                      fontSize={{ md: "22px", xs: "13px" }}
                      textAlign={{
                        xs: "center",
                        md: "left",
                        overflowWrap: "break-word",
                        display: "-webkit-box",
                        WebkitLineClamp: matches ? "1" : "3",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        lineHeight: "20px",
                      }}
                    >
                      {name}
                    </Typography>
                    <Typography
                      textAlign={{
                        xs: "center",
                        md: "left",
                      }}
                      mt={{ xs: 0, md: 1 }}
                      letterSpacing="0.03em"
                      textTransform="uppercase"
                      color="rgba(217, 217, 217, 0.69)"
                      fontSize={{ md: "20px", xs: "16px" }}
                    >
                      Quantity:{quantity}
                    </Typography>
                  </Box>

                  <Typography
                    ml={{
                      xs: 1,
                      md: 3,
                    }}
                    sx={{
                      fontSize: { xs: "12px", md: "24px !important" },
                    }}
                    textAlign={"right"}
                    variant="subtitle1"
                    fontSize="22px"
                  >
                    $ {price}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <Typography textAlign={"center"} variant="h2" mt={5}>
              Your cart is empty. Please add some items to continue.
            </Typography>
          )}
        </Grid>
        {cart?.length > 0 && (
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              boxSizing: "border-box",
              background: "#121B25",
              borderRadius: "18px",
              p: { xs: 1, md: 2 },
              mt: 3.5,
              mx: { xs: "10px", md: "0px" },
            }}
          >
            <Box p={1}>
              <Typography variant="h3">Checkout Summary</Typography>
              <Box display="flex" justifyContent="space-between" my={2.5}>
                <Typography textTransform="uppercase" variant="body1">
                  Subtotal
                </Typography>
                <Typography textTransform="uppercase" variant="body1">
                  ${" "}
                  {cart?.reduce((total, curr) => {
                    return total + curr.price * curr.quantity;
                  }, 0)}
                </Typography>
              </Box>
              {/* <Box display="flex" justifyContent="space-between">
                <Typography textTransform="uppercase" variant="body1">
                  Discount code
                </Typography>
                <Typography textTransform="uppercase" variant="body1">
                  -$ 0.00
                </Typography>
              </Box>
              <CustomTextField
                placeholder="Discount Code"
                fullWidth
                sx={{
                  mt: 2,
                  mb: 1.2,
                  textTransform: "uppercase",
                }}
              />
              <Typography variant="subtypo">
                To get further holded discount. You need to
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "14px !important",
                  fontFamily: "Open Sans !important",
                }}
              >
                Connect your wallet.
              </Typography> */}
              <Box display="flex" my={"20px"} justifyContent="space-between">
                <Typography textTransform="uppercase" variant="body1">
                  Total
                </Typography>
                <Typography textTransform="uppercase" variant="subtitle1">
                  ${" "}
                  {cart?.reduce((total, curr) => {
                    return total + curr.price * curr.quantity;
                  }, 0)}
                </Typography>
              </Box>
              <Box
                sx={{
                  ...gradientButton,
                  "&:hover": {
                    background:
                      "linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%), linear-gradient(#121B25, #121B25  )",
                  },
                }}
              >
                <Button
                  variant="gradienttext"
                  sx={{
                    width: "100%",
                    py: 1,
                    fontSize: "18px",
                  }}
                  onClick={proceedToCheckout}
                >
                  Proceed to Checkout
                </Button>
              </Box>
              <Typography
                sx={{
                  mt: 1.5,
                  color: "rgba(220, 220, 220, 0.69)",
                  fontFamily: "Open Sans",
                  fontSize: "14px !important",
                  fontWeight: "400",
                  textAlign: "center ",
                }}
              >
                Taxes and shipping calculated at checkout.
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CartItem;
