import { api } from "./api";

export const palaceOrder = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    orderApi: builder.mutation({
      query: (body) => {
        return { url: "/place-order", method: "POST", body: body };
      },
      invalidatesTags: [
        "getSingleCollection",
        "getSingleWeb3Product",
        "getSingleWeb2Product",
      ],
    }),
    checkQuantityApi: builder.mutation({
      query: (body) => {
        return { url: "/check-quantity", method: "POST", body: body };
      },
      invalidatesTags: [
        "getSingleCollection",
        "getSingleWeb3Product",
        "getSingleWeb2Product",
      ],
    }),
  }),
});
export const { useOrderApiMutation, useCheckQuantityApiMutation } = palaceOrder;
