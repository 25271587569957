import React, { useContext, useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { AppContext } from "../utils";
import { Link } from "react-router-dom";
import { ToastNotify } from "./SmallComponents/AppComponents";
import { useDispatch } from "react-redux";
import { addtokenGatedArray } from "../slice/nftImgSlice";
import { openCollectionLockedDialog } from "../services/appDialogStates";

const Web3product = ({ data, nftBalance }) => {
  const dispatch = useDispatch();
  const [productArray, setProductArray] = useState([]);
  const [reverseProductArray, setReverseProductArray] = useState([]);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const { account } = useContext(AppContext);
  const tokenArrayFound = data?.collectionProduct?.tokenArray;

  const openAlert = () => {
    if (account) {
      if (nftBalance === 0) {
        return dispatch(
          openCollectionLockedDialog({
            open: true,
            message: data?.collectionProduct?.name,
            contractAddress: tokenArrayFound[0]?.tokenAddress,
          })
        );
      }
    } else {
      setAlertState({
        open: true,
        message: "Please, connect your wallet.",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    let newData = data?.collectionProduct?.products;
    if (newData) {
      setProductArray(newData);
      const dataArray = [...productArray];
      setReverseProductArray(dataArray.reverse());
    }
  }, [data, productArray]);

  useEffect(() => {
    // const tokenArrayFound = data?.collectionProduct?.tokenArray;
    // console.log(tokenArrayFound);
    dispatch(addtokenGatedArray(tokenArrayFound));
    localStorage.setItem(
      "gatedStatus",
      JSON.stringify(tokenArrayFound?.length > 0 ? true : false)
    );
  }, [data, dispatch, tokenArrayFound]);
  return (
    <>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />

      <Grid container spacing={7}>
        {reverseProductArray?.length > 0 &&
          reverseProductArray?.map(
            ({ images, name, price, description, _id }, i) => {
              return (
                <Grid item xs={12} md={4} key={i}>
                  <Link
                    to={
                      !tokenArrayFound?.length > 0 || nftBalance > 0
                        ? `/collection/webproductdetail/${_id}`
                        : ""
                    }
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      onClick={() => tokenArrayFound?.length > 0 && openAlert()}
                      sx={{
                        color: "#dcdcdc",
                        height: "100%",
                        borderRadius: "18px",
                        border: "1px transparent",
                        backgroundImage:
                          " linear-gradient(#121B25, #121B25  ),linear-gradient(37.34deg, rgba(72, 77, 82, 0.75) 4.36%, rgba(142, 146, 151, 0.75) 48.4%, rgba(111, 123, 136, 0.75) 96.11%)",
                        backgroundOrigin: "border-box",
                        backgroundClip: "content-box, border-box",
                        padding: "2px",
                        "&:hover": {
                          backgroundImage:
                            " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
                        },
                      }}
                    >
                      <Box p={2}>
                        <img
                          src={`${images[0]}`}
                          style={{
                            width: "100%",
                            height: "375px",
                            borderRadius: "10px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mt={1}
                          mb={2}
                        >
                          <Typography
                            variant="h2"
                            sx={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {name}
                          </Typography>
                          <Box
                            sx={{
                              width:
                                !tokenArrayFound?.length > 0 || nftBalance > 0
                                  ? "180px"
                                  : "0px",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              display={
                                !tokenArrayFound?.length > 0 || nftBalance > 0
                                  ? "flex"
                                  : "none"
                              }
                            >
                              $ {price}
                            </Typography>
                          </Box>
                        </Box>

                        <Typography
                          variant="subtypo"
                          sx={{
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {description}
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
              );
            }
          )}
      </Grid>
    </>
  );
};

export default Web3product;
