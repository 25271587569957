import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";

import CollectCards from "./CollectCards";
import UnFeatured from "../../Components/UnFeatured";

export default function Collections() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Box py={10}>
        <Container maxWidth="lg">
          <Typography variant="h1" textAlign="center" mb={4}>
            Collections
          </Typography>
          <Box mb={4}>
            <CollectCards />
          </Box>
          <Box mb={4}>
            <UnFeatured />
          </Box>
        </Container>
      </Box>
    </>
  );
}
