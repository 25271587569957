import { Routes, Route } from "react-router-dom";
import "./App.css";
import Collections from "./Pages/LandingPage/Collections";
import Header from "./Components/Header";
import BuyProduct from "./Pages/LandingPage/BuyProduct";
import Footer from "./Pages/LandingPage/Footer";
import Home from "./Pages/LandingPage/Home";
import Products from "./Pages/LandingPage/Products";
import { DetailCollection } from "./Pages/Admin/DetailCollection";
import AboutCollect from "./Pages/LandingPage/AboutCollect";
import DetailProduct from "./Pages/LandingPage/DetailProduct";
import WebProductDetails from "./Pages/LandingPage/WebProductDetail";
import CheackoutSuccess from "./Components/cheackoutSuccess";
import PaymentFail from "./Components/paymentFail";
import Web3 from "web3";
import { useContext, useEffect, useState } from "react";
import NetworkChange from "./networkSwitch";
import CollectionLockedDialog from "./Components/CollectionLockedDialog";
import { AppContext } from "./utils";

const web3 = new Web3(
  Web3.givenProvider
    ? Web3.givenProvider
    : "https://mainnet.infura.io/v3/36fc2665f5504bc18c3b7f9fab0e0c46"
);
function App() {
  const [open, setOpen] = useState(false);
  const { account } = useContext(AppContext);

  let chain = async () => {
    const chainid = await web3.eth.getChainId();
    if (chainid !== 1) {
      setOpen(true);
    }
  };
  useEffect(() => {
    if (account) {
      chain();
    }
  }, [account]);

  return (
    <>
      <NetworkChange open={open} setOpen={setOpen} />
      <Header />
      <CollectionLockedDialog />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="cart" element={<BuyProduct />} />
        <Route path="/collection" element={<Collections />} />
        <Route path="/products/productdetail/:id" element={<DetailProduct />} />
        <Route path="/detailCollection" element={<DetailCollection />} />
        <Route
          path="/collection/webproductdetail/:id"
          element={<WebProductDetails />}
        />
        <Route path="/products" element={<Products />} />
        <Route path="/cheackout_success" element={<CheackoutSuccess />} />
        <Route path="/paymentFail" element={<PaymentFail />} />
        <Route
          path="/collection/collectiondetails/:id"
          element={<AboutCollect />}
        />
        <Route path="products" element={<Products />} />
      </Routes>
      <Footer />
    </>
  );
}
export default App;
