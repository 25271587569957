import React, { useEffect, useContext } from "react";
import { AppContext } from "../../utils";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  styled,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { gradientButton } from "../../Components/SmallComponents/AppComponents";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useGetSingleWeb3ProductQuery } from "../../services/web3ProductApi";
import Loading from "../../Components/SmallComponents/loading";
import { addProduct } from "../../slice/slice";
// import RemoveIcon from "@mui/icons-material/Remove";
// import AddIcon from "@mui/icons-material/Add";
import { BackButton } from "../../Components/SmallComponents/BackButton";
import { useCustmizedImagesApiMutation } from "../../services/custmizedImages";
import { useGetNftImagesMutation } from "../../services/collectionApi";
import { ToastNotify } from "../../Components/SmallComponents/AppComponents";
import { Contract } from "ethers";
import tokenAbi from "../../ConnectivityAssets/tokenAbi.json";
import Marquee from "react-fast-marquee";

const StyledMarquee = styled(Marquee)({
  "&.marquee-container": {
    overflowX: "scroll !important",
  },
});

export default function WebProductDetails() {
  const gatedStatus = localStorage.getItem("gatedStatus");
  const updatedGatedStatus = JSON.parse(gatedStatus);

  const { account, signer } = useContext(AppContext);
  const [counter, setCounter] = useState(1);
  const matches = useMediaQuery("(max-width:960px)");
  const { id } = useParams();
  const {
    data = [],
    error,
    isFetching,
    isLoading,
  } = useGetSingleWeb3ProductQuery(id);

  const [custmizedImages] = useCustmizedImagesApiMutation();
  const [nftImagesApi] = useGetNftImagesMutation();

  const [image, setImage] = useState(data?.data?.images[0]);
  const [imgNumber, setImgNumber] = useState(1);
  const [singleBaseUrl, setSingleBaseUrl] = useState("");
  const [singleImg, setSingleImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [multipalImg, setMultipalImg] = useState([]);
  const [nftImagesArray, setNftImagesArray] = useState([]);

  const [multipalBaseURL, setMultipalBaseURL] = useState([]);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const tokenGatedArrayStore = useSelector(
    (state) => state.nftImages.tokenGatedArray
  );
  const toastShowing = (displayMessage) => {
    setAlertState({
      open: true,
      message: displayMessage,
      severity: "error",
    });
  };
  // const cartToast = () => {
  //   setAlertState({
  //     open: true,
  //     message: "Item added to your cart!",
  //     severity: "success",
  //   });
  // };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addToCartProduct = async (status) => {
    try {
      if (
        // !data?.data?.heatPress &&
        // !data?.data?.embossing &&
        // !data?.data?.custom
        tokenGatedArrayStore?.length === 0 &&
        singleImg?.length === 0 &&
        multipalImg?.length === 0
      ) {
        dispatch(
          addProduct({
            ...data?.data,
            quantity: counter,
            imgId: "",
          })
        );
      } else {
        if (
          tokenGatedArrayStore?.length > 0 &&
          !singleImg &&
          multipalImg.length === 0
        ) {
          return toastShowing("Select your NFTs to customize your product.");
        }
        if (
          tokenGatedArrayStore?.length > 0 &&
          multipalImg.length > 0 &&
          multipalImg.length < 2
        ) {
          return toastShowing("Select your NFTs to customize your product.");
        }
        const formData = new FormData();

        formData.append("data", JSON.stringify({ productId: id }));
        if (singleImg) {
          if (typeof singleImg === "string") {
            formData.append("image", JSON.stringify([singleImg]));
          } else {
            console.log(typeof singleImg);
            formData.append("file", singleImg);
          }
        } else {
          if (
            typeof multipalImg[0] === "string" &&
            typeof multipalImg[1] === "string"
          ) {
            formData.append("image", JSON.stringify(multipalImg));
          } else {
            // eslint-disable-next-line array-callback-return
            multipalImg.map((info, i) => {
              if (typeof info === "string") {
                formData.append(
                  "image",
                  JSON.stringify({ index: i, imgUrl: info })
                );
              }
              if (typeof info === "object") {
                formData.append("file", info);
              }
            });
          }
        }
        setLoading(true);
        const { data: dataS, error } = await custmizedImages(formData);
        if (error) {
          setLoading(false);
          return setAlertState({
            open: true,
            message: "Please, again upload image",
            severity: "error",
          });
        }
        dispatch(
          addProduct({
            ...data?.data,
            quantity: counter,
            imgId: dataS?.data ?? "",
          })
        );
        setLoading(false);
      }

      if (status) {
        navigate("/cart");
      }
      if (!status) {
        setAlertState({
          open: true,
          message: "Item added to your cart!",
          severity: "success",
        });
        setSingleBaseUrl("");
        setSingleImg("");
        setMultipalImg([]);
        setMultipalBaseURL([]);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const boxArray = data?.data?.images;
  const imgHover = (index) => {
    setImage(index);
  };
  useEffect(() => {
    imgHover();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (tokenGatedArrayStore?.length > 0 && account) {
      (async () => {
        try {
          const { data, error } = await nftImagesApi({
            account: account,
            tokensArray: tokenGatedArrayStore,
          });
          if (error) {
            return setLoading(false);
          }
          setNftImagesArray(data);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [tokenGatedArrayStore, account, nftImagesApi, signer]);

  useEffect(() => {
    if (!account && updatedGatedStatus) {
      navigate(-1);
    }
  }, [account, navigate, updatedGatedStatus]);

  // const incrementQuantity = () => {
  //   if (counter < data?.data?.totalQuantity || data?.data?.unLimitedStock) {
  //     setCounter(counter + 1);
  //   }
  // };
  // const decrementQuantity = () => {
  //   if (counter === 1) {
  //     setCounter(1);
  //   } else {
  //     setCounter(counter - 1);
  //   }
  // };
  const handleSingleFile = async (event) => {
    const actualSize = 10 * 1024 * 1024;

    if (event.target.files[0]?.size > actualSize) {
      if (event?.target?.value) {
        event.target.value = "";
      }
      toastShowing(
        "Sorry, your image exceeds the maximum size allowed. Please upload an image that is smaller than 10 mb."
      );
    } else {
      let file = event.target.files[0];
      setSingleImg(file);
      const urlFILE = URL.createObjectURL(file);
      setSingleBaseUrl(urlFILE);
    }
  };

  const handleMultipulFile = async (event, i) => {
    const actualSize = 10 * 1024 * 1024;
    if (event.target.files[0]?.size > actualSize) {
      if (event?.target?.value) {
        event.target.value = "";
      }
      toastShowing(
        "Sorry, your image exceeds the maximum size allowed. Please upload an image that is smaller than 10 mb."
      );
    } else {
      [...event.target.files].forEach((file) => {
        const updateBaseUrl = [...multipalBaseURL];
        updateBaseUrl[i] = URL.createObjectURL(file);
        setMultipalBaseURL(updateBaseUrl);
        const updateMultipalImg = [...multipalImg];
        updateMultipalImg[i] = file;
        setMultipalImg(updateMultipalImg);
      });
      if (i === 0) {
        setImgNumber(2);
      }
      if (i === 1) {
        setImgNumber(1);
      }
    }
  };
  return (
    <>
      <Loading loading={isLoading} />
      <Loading loading={isFetching} />
      <Loading loading={loading} />

      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Box py={6} mb={10}>
        <Container maxWidth="lg">
          <Box pb={4}>
            <BackButton />
          </Box>
          <Grid container spacing={7}>
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  height: matches ? "400px" : "568px",
                  borderRadius: "18px",
                  border: "1px transparent",
                  backgroundImage:
                    " linear-gradient(#121B25, #121B25  ),linear-gradient(37.34deg, rgba(72, 77, 82, 0.75) 4.36%, rgba(142, 146, 151, 0.75) 48.4%, rgba(111, 123, 136, 0.75) 96.11%)",
                  backgroundOrigin: "border-box",
                  backgroundClip: "content-box, border-box",
                  padding: "2px",
                  "&:hover": {
                    backgroundImage:
                      " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
                  },
                }}
              >
                <img
                  src={image ? `${image}` : `${data?.data?.images[0]}`}
                  width="100%"
                  height="100%"
                  style={{ padding: "10px", borderRadius: "24px" }}
                  alt="images"
                />
              </Box>

              <Box mt={4} display="flex" justifyContent="space-around">
                {boxArray?.map((item, index) => {
                  return (
                    <Box key={index}>
                      <img
                        src={`${item}`}
                        alt=""
                        width="80px"
                        height="80px"
                        style={{ borderRadius: "6px", objectFit: "cover" }}
                        onMouseOver={() => imgHover(item)}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography variant="body1" mt={2}>
                {data?.data?.collectionName}
              </Typography>
              <Typography variant="h1" mt={2}>
                {data?.data?.name}
              </Typography>
              <Box display="flex" mt={4} alignItems="center">
                <Typography variant="subtitle3">
                  $ {data?.data?.price}
                </Typography>
                {/* <Typography variant="body1" ml={2}>
                  {data?.data?.discount > 0 ? (
                    <s>{data?.data?.discount}</s>
                  ) : null}
                </Typography> */}
              </Box>
              <Box
                mt={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {/* <Box
                  display="flex"
                  width={matches ? "150px" : "200px"}
                  height={"60px"}
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    borderRadius: "18px",
                    border: "1px transparent",
                    backgroundImage:
                      " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box, border-box",
                    padding: "2px",
                    "&:hover": {
                      backgroundImage:
                        " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
                    },
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Box
                      mx={1}
                      onClick={decrementQuantity}
                      sx={{
                        background: "#19C59F",
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                      }}
                    >
                      <RemoveIcon sx={{ color: "#000", cursor: "pointer" }} />
                    </Box>
                    <Typography mx={2}>{counter}</Typography>
                    <Box
                      mx={1}
                      mr={2}
                      onClick={incrementQuantity}
                      sx={{
                        background: "#19C59F",
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                      }}
                    >
                      <AddIcon sx={{ color: "#000", cursor: "pointer" }} />
                    </Box>
                  </Box>
                </Box> */}
                {data?.data?.unLimitedStock === false &&
                  data?.data?.totalQuantity > 0 && (
                    <Typography variant="h2">
                      Total Quantity : {data?.data?.totalQuantity}
                    </Typography>
                  )}
              </Box>
              {data?.data?.totalQuantity === 0 && (
                <Typography variant="h2" mt={"20px"}>
                  This product is out of stock.
                </Typography>
              )}

              {data?.data?.totalQuantity === 0 &&
              data?.data?.unLimitedStock === false ? null : data?.data
                  ?.heatPress === false &&
                data?.data?.embossing === false &&
                data?.data?.custom === false ? null : (
                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "60px",
                    width: "100%",
                    borderRadius: "18px",
                    border: "1px transparent",
                    backgroundImage:
                      " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
                    // " linear-gradient(#121B25, #121B25  ),linear-gradient(37.34deg, rgba(72, 77, 82, 0.75) 4.36%, rgba(142, 146, 151, 0.75) 48.4%, rgba(111, 123, 136, 0.75) 96.11%)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box, border-box",
                    padding: "2px",
                    "&:hover": {
                      backgroundImage:
                        " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
                    },
                  }}
                >
                  <Box ml={2} display="flex" alignItems="center">
                    <Checkbox
                      checked
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                      sx={{
                        color: "rgba(217, 217, 217, 0.69)",
                        "&.Mui-checked": {
                          color: "#15BDAC",
                        },
                      }}
                    />
                    <Typography variant="body1" ml={1}>
                      {data?.data?.heatPress
                        ? "Heatpress"
                        : data?.data?.embossing
                        ? "Embossing"
                        : "Custom"}
                    </Typography>
                  </Box>
                </Box>
              )}
              {tokenGatedArrayStore?.length > 0 &&
              nftImagesArray?.length > 0 &&
              data?.data?.totalQuantity !== 0 ? (
                <Box
                  sx={{
                    border: "3px solid rgba(82, 89, 96, 0.26)",
                    borderRadius: "18px",
                    background: "#13141E",
                    p: 2,
                    my: 2,
                  }}
                >
                  {(nftImagesArray?.length > 0 &&
                    tokenGatedArrayStore?.length > 0 &&
                    data?.data?.heatPressType) ||
                  data?.data?.custom ||
                  data?.data?.embossing ? (
                    <StyledMarquee
                      pauseOnHover={true}
                      pauseOnClick={true}
                      speed={0}
                      gradient={false}
                    >
                      {nftImagesArray?.map((item, index) => {
                        return (
                          <Box
                            key={index}
                            mr={1.5}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              if (
                                (data?.data?.heatPress &&
                                  data?.data?.heatPressType ===
                                    "Only One Side Custom") ||
                                (data?.data?.custom &&
                                  data?.data?.customType ===
                                    "Only One Side Custom") ||
                                data?.data?.embossing
                              ) {
                                setSingleBaseUrl(item);
                                setSingleImg(item);
                              } else {
                                const updateBaseUrl = [...multipalBaseURL];
                                const updateMultipalImg = [...multipalImg];

                                if (imgNumber % 2 === 0) {
                                  setImgNumber(1);
                                  updateBaseUrl[1] = item;
                                  updateMultipalImg[1] = item;
                                  setMultipalBaseURL(updateBaseUrl);
                                  setMultipalImg(updateMultipalImg);
                                } else {
                                  setImgNumber(2);
                                  updateBaseUrl[0] = item;
                                  updateMultipalImg[0] = item;
                                  setMultipalBaseURL(updateBaseUrl);
                                  setMultipalImg(updateMultipalImg);
                                }
                              }
                            }}
                          >
                            <img
                              src={`${item}`}
                              alt=""
                              width="80px"
                              height="80px"
                              style={{
                                borderRadius: "6px",
                                objectFit: "cover",
                              }}
                            />
                          </Box>
                        );
                      })}
                    </StyledMarquee>
                  ) : (
                    <Box py={5}>
                      <Box sx={{ position: "relative", margin: "auto" }}>
                        <Typography
                          variant="h1"
                          className="loadingTextNft"
                          data-loading-text="LOADING..."
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              ) : null}
              {data?.data?.totalQuantity === 0 &&
              data?.data?.unLimitedStock === false ? null : (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection={matches ? "column" : "row"}
                  mt={3}
                >
                  {data?.data?.heatPress ? (
                    <Box
                      sx={{
                        width: matches ? "100%" : "48%",
                      }}
                    >
                      <Box display="flex" justifyContent="space-between">
                        {data?.data?.heatPressType === "Front & Back Custom" ? (
                          ["Front", "Back"].map((name, index) => (
                            <Box
                              key={name}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#121B25",
                                width: "48%",
                                height: "140px",
                                border: "3px dashed rgba(82, 89, 96, 0.26)",
                                cursor: "pointer",
                                borderRadius: "18px",
                                overflow: "hidden",
                                "&:hover": {
                                  border: "3px dashed green",
                                },
                              }}
                            >
                              <label
                                htmlFor={name}
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                {tokenGatedArrayStore?.length > 0 ? (
                                  multipalBaseURL[index] ? (
                                    <img
                                      src={multipalBaseURL[index]}
                                      style={{
                                        height: "140px",
                                      }}
                                      alt=""
                                    />
                                  ) : (
                                    <Typography mt={1}>{name}</Typography>
                                  )
                                ) : (
                                  <>
                                    <input
                                      id={name}
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) =>
                                        handleMultipulFile(e, index)
                                      }
                                      style={{ display: "none" }}
                                    />
                                    {multipalBaseURL[index] ? (
                                      <img
                                        src={multipalBaseURL[index]}
                                        style={{
                                          height: "140px",
                                        }}
                                        alt=""
                                      />
                                    ) : (
                                      <Typography mt={1}>{name}</Typography>
                                    )}
                                  </>
                                )}
                              </label>
                            </Box>
                          ))
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              justifyContent: "center",
                              background: "#121B25",
                              width: "48%",
                              height: "140px",
                              border: "3px dashed rgba(82, 89, 96, 0.26)",
                              cursor: "pointer",
                              borderRadius: "18px",
                              overflow: "hidden",
                              "&:hover": {
                                border: "3px dashed green",
                              },
                            }}
                          >
                            <label
                              htmlFor="oneSide"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              {tokenGatedArrayStore?.length > 0 ? (
                                singleBaseUrl ? (
                                  <img
                                    src={singleBaseUrl}
                                    style={{
                                      height: "140px",
                                    }}
                                    alt=""
                                  />
                                ) : (
                                  <Typography mt={1}>Select NFT</Typography>
                                )
                              ) : (
                                <>
                                  <input
                                    id="oneSide"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleSingleFile}
                                    style={{ display: "none" }}
                                  />
                                  {singleBaseUrl ? (
                                    <img
                                      src={singleBaseUrl}
                                      style={{
                                        height: "140px",
                                      }}
                                      alt=""
                                    />
                                  ) : (
                                    <Typography mt={1}>Upload image</Typography>
                                  )}
                                </>
                              )}
                            </label>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ) : null}
                  {data?.data?.custom ? (
                    <Box
                      sx={{
                        width: matches ? "100%" : "48%",
                      }}
                    >
                      <Box display="flex" justifyContent="space-between">
                        {data?.data?.customType === "Front & Back Custom" ? (
                          ["Front", "Back"].map((name, index) => (
                            <Box
                              key={name}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#121B25",
                                width: "48%",
                                height: "140px",
                                border: "3px dashed rgba(82, 89, 96, 0.26)",
                                cursor: "pointer",
                                borderRadius: "18px",
                                overflow: "hidden",
                              }}
                            >
                              <label
                                htmlFor={name}
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                {tokenGatedArrayStore?.length > 0 ? (
                                  multipalBaseURL[index] ? (
                                    <img
                                      src={multipalBaseURL[index]}
                                      style={{
                                        height: "140px",
                                      }}
                                      alt=""
                                    />
                                  ) : (
                                    <Typography mt={1}>{name}</Typography>
                                  )
                                ) : (
                                  <>
                                    <input
                                      id={name}
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) =>
                                        handleMultipulFile(e, index)
                                      }
                                      style={{ display: "none" }}
                                    />
                                    {multipalBaseURL[index] ? (
                                      <img
                                        src={multipalBaseURL[index]}
                                        style={{
                                          height: "140px",
                                        }}
                                        alt=""
                                      />
                                    ) : (
                                      <Typography mt={1}>{name}</Typography>
                                    )}
                                  </>
                                )}
                              </label>
                            </Box>
                          ))
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              justifyContent: "center",
                              background: "#121B25",
                              width: "48%",
                              height: "140px",
                              border: "3px dashed rgba(82, 89, 96, 0.26)",
                              cursor: "pointer",
                              borderRadius: "18px",
                              overflow: "hidden",
                            }}
                          >
                            <label
                              htmlFor="oneSide"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              {tokenGatedArrayStore?.length > 0 ? (
                                singleBaseUrl ? (
                                  <img
                                    src={singleBaseUrl}
                                    style={{
                                      height: "140px",
                                    }}
                                    alt=""
                                  />
                                ) : (
                                  <Typography mt={1}>Select NFT</Typography>
                                )
                              ) : (
                                <>
                                  <input
                                    id="oneSide"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleSingleFile}
                                    style={{ display: "none" }}
                                  />
                                  {singleBaseUrl ? (
                                    <img
                                      src={singleBaseUrl}
                                      style={{
                                        height: "140px",
                                      }}
                                      alt=""
                                    />
                                  ) : (
                                    <Typography mt={1}>Upload image</Typography>
                                  )}
                                </>
                              )}
                            </label>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ) : null}
                  {data?.data?.embossing ? (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      sx={{
                        width: matches ? "100%" : "48%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          background: "#121B25",
                          width: "48%",
                          height: "140px",
                          border: "3px dashed rgba(82, 89, 96, 0.26)",
                          cursor: "pointer",
                          borderRadius: "18px",
                          overflow: "hidden",
                        }}
                      >
                        <label
                          htmlFor="oneSide"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          {tokenGatedArrayStore?.length > 0 ? (
                            singleBaseUrl ? (
                              <img
                                src={singleBaseUrl}
                                style={{
                                  height: "140px",
                                }}
                                alt=""
                              />
                            ) : (
                              <Typography mt={1}>Select NFT</Typography>
                            )
                          ) : (
                            <>
                              <input
                                id="oneSide"
                                type="file"
                                accept="image/*"
                                onChange={handleSingleFile}
                                style={{ display: "none" }}
                              />
                              {singleBaseUrl ? (
                                <img
                                  src={singleBaseUrl}
                                  style={{
                                    height: "140px",
                                  }}
                                  alt=""
                                />
                              ) : (
                                <Typography mt={1}>Upload image</Typography>
                              )}
                            </>
                          )}
                        </label>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              )}

              {data?.data?.totalQuantity === 0 &&
              data?.data?.unLimitedStock === false ? null : data?.data
                  ?.heatPress === false &&
                data?.data?.embossing === false &&
                data?.data?.custom === false ? null : (
                <>
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Typography variant="body1" mt={matches ? 2 : 0}>
                      Info:
                    </Typography>
                    {tokenGatedArrayStore?.length > 0 ? (
                      <Typography variant="subtypo" mt={1}>
                        • Select your NFTs to customize your product.
                      </Typography>
                    ) : data?.data?.heatPress || data?.data?.custom ? (
                      <Typography variant="subtypo" mt={1}>
                        • To avoid white edges, extend your design to the bleed
                        line – but keep text and images within the safety area.
                        <br />• Photographs don't work well.
                      </Typography>
                    ) : (
                      <Typography variant="subtypo" mt={1}>
                        • If you are planning of getting a patch – max size for
                        the size will be 2.5-in W * 1.25-in H.
                        <br />• If there are too many small lines, there is a
                        chance that the Deboss might not come out as intended.
                        <br />• Photographs don't work well.
                      </Typography>
                    )}
                  </Box>
                </>
              )}

              <Box display="flex" justifyContent="space-between" mt={4}>
                <Box
                  width="48%"
                  sx={{
                    ...gradientButton,
                    background:
                      data?.data?.totalQuantity === 0 &&
                      data?.data?.unLimitedStock === false
                        ? "grey"
                        : "",
                    "&:hover": {
                      background:
                        data?.data?.totalQuantity === 0 &&
                        data?.data?.unLimitedStock === false
                          ? "grey"
                          : "linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%), linear-gradient(#121B25, #121B25  )",
                    },
                  }}
                >
                  <Button
                    disabled={
                      data?.data?.totalQuantity === 0 &&
                      data?.data?.unLimitedStock === false
                    }
                    onClick={() => addToCartProduct(true)}
                    variant={
                      data?.data?.totalQuantity === 0 &&
                      data?.data?.unLimitedStock === false
                        ? ""
                        : "gradienttext"
                    }
                    sx={{ height: "61px", width: "100%", fontSize: "22px" }}
                  >
                    Buy Now
                  </Button>
                </Box>
                <Box
                  width="48%"
                  sx={{
                    ...gradientButton,
                    background:
                      data?.data?.totalQuantity === 0 &&
                      data?.data?.unLimitedStock === false
                        ? "grey"
                        : "",
                    "&:hover": {
                      background:
                        data?.data?.totalQuantity === 0 &&
                        data?.data?.unLimitedStock === false
                          ? "grey"
                          : "linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%), linear-gradient(#121B25, #121B25  )",
                    },
                  }}
                >
                  <Button
                    variant={
                      data?.data?.totalQuantity === 0 &&
                      data?.data?.unLimitedStock === false
                        ? ""
                        : "gradienttext"
                    }
                    disabled={
                      data?.data?.totalQuantity === 0 &&
                      data?.data?.unLimitedStock === false
                    }
                    onClick={() => {
                      addToCartProduct(false);
                    }}
                    sx={{
                      height: "61px",
                      width: "100%",
                      fontSize: "22px",
                    }}
                  >
                    ADD TO CART
                  </Button>
                </Box>
              </Box>

              <Typography variant="h2" mt={6} mb={2}>
                Description
              </Typography>
              <Typography variant="subtypo" mb={1}>
                {data?.data?.description}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        {error && <Typography>something is Wrong</Typography>}
      </Box>
    </>
  );
}
