import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

import { useGetCollectionDataQuery } from "../../services/collectionApi";
import Loading from "../../Components/SmallComponents/loading";
import { lockimg } from "../../Components/SmallComponents/Images";

export default function CollectCards() {
  const { data, isLoading, isFetching } = useGetCollectionDataQuery();
  const location = useLocation();
  return (
    <>
      <Loading loading={isLoading} />
      <Loading loading={isFetching} />
      <Grid container spacing={data?.featuredCollection.length > 0 ? 7 : 0}>
        {data?.featuredCollection.length > 0 ? (
          data?.featuredCollection.map(
            ({ _id, name, description, logo, tokenArray }, i) => {
              return (
                <Grid item xs={12} md={4} key={i}>
                  <Link
                    to={`/collection/collectiondetails/${_id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        borderRadius: "18px",
                        border: "1px transparent",
                        backgroundImage:
                          " linear-gradient(#121B25, #121B25  ),linear-gradient(37.34deg, rgba(72, 77, 82, 0.75) 4.36%, rgba(142, 146, 151, 0.75) 48.4%, rgba(111, 123, 136, 0.75) 96.11%)",
                        backgroundOrigin: "border-box",
                        backgroundClip: "content-box, border-box",
                        padding: "2px",
                        "&:hover": {
                          backgroundImage:
                            " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
                        },
                      }}
                    >
                      <Box p={2} position="relative">
                        <Box
                          sx={{
                            position: "absolute",
                            top: "20px",
                            right: "15px",
                            display: tokenArray?.length > 0 ? "block" : "none",
                          }}
                        >
                          <img src={lockimg} width="52px" alt="lock" />
                        </Box>
                        <img
                          src={`${logo}`}
                          style={{
                            width: "100%",
                            height: "240px",
                            borderRadius: "10px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                        <Typography
                          variant="h3"
                          color="#dcdcdc"
                          mt={1}
                          mb={2}
                          sx={{
                            display: "-webkit-box",
                            WebkitLineClamp: "1",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {name}
                        </Typography>
                        <Typography
                          variant="subtypo"
                          sx={{
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {description}
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
              );
            }
          )
        ) : (
          <>
            {location?.pathname === "/collection" ? null : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="200px"
              >
                <Typography textAlign="center">
                  {data?.featuredCollection?.length === 0 &&
                  data?.unFeaturedCollection?.length > 0
                    ? "All collections are unfeatured!"
                    : "No collections added yet"}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Grid>
    </>
  );
}
