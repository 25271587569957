import { useState } from "react";
import { Alert, Box, Snackbar, Tabs, Typography } from "@mui/material";
import { Button } from "@mui/material";

import { useSelector } from "react-redux";

export const gradientButton = {
  border: "1px transparent",
  backgroundImage:
    " linear-gradient(#121B25, #121B25),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
  backgroundOrigin: "border-box",
  backgroundClip: "content-box, border-box",
  padding: "2px",
  borderRadius: "14px",
  "&:hover": {
    backgroundImage:
      "linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%), linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
  },
};

export const ShippingCard = () => {
  const cart = useSelector((store) => store.cart);

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        background: "#121B25",
        borderRadius: "18px",
        pt: { xs: 3, md: 3 },
        px: { xs: 1, md: 2 },
        pb: 2.5,
        mx: { xs: "5px", md: "0px" },
        mt: { xs: "30px", md: "0px" },
      }}
    >
      {cart?.length > 0 &&
        cart?.map(
          ({ name, _id, quantity, price, images, discountCode }, index) => {
            return (
              <Box
                key={index}
                display="flex"
                //   flexDirection={{ xs: "column", md: "row" }}
                gap={{ xs: "7px", md: "20px" }}
                alignItems="center"
                mb="20px"
              >
                <Box
                  sx={{
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "center",
                    border: "2px solid #4A4F56",
                    py: "2px",
                    px: "6px",
                    borderRadius: "6px",
                  }}
                >
                  <img
                    src={`${images[0]}`}
                    style={{
                      width: "48px",
                      height: "60px",
                    }}
                    alt=" bag"
                  />
                </Box>

                <Box display="flex" flexGrow={1} flexDirection={"column"}>
                  <Typography
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      color: "#d9d9d9",
                      fontSize: "14px !important",
                    }}
                  >
                    {name}
                  </Typography>
                  <Typography
                    mt={1}
                    letterSpacing="0.03em"
                    textTransform="uppercase"
                    color="rgba(217, 217, 217, 0.69)"
                    fontSize="14px !important"
                  >
                    Quantity:{quantity}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "180px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography variant="body1">$ {price}</Typography>
                </Box>
              </Box>
            );
          }
        )}
      <Box display="flex" justifyContent="space-between" my={2.5}>
        <Typography textTransform="uppercase" variant="body1">
          Subtotal
        </Typography>
        <Typography textTransform="uppercase" variant="body1">
          ${" "}
          {cart?.reduce((total, curr) => {
            return total + curr.price * curr.quantity;
          }, 0)}
        </Typography>
      </Box>

      <Box display="flex" mb={2.5} justifyContent="space-between">
        <Typography textTransform="uppercase" variant="body1">
          Shipping
        </Typography>
        <Typography textTransform="uppercase" variant="body1"></Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography textTransform="uppercase" variant="body1">
          Total
        </Typography>
        <Typography variant="subtitle1">
          ${" "}
          {cart?.reduce((total, curr) => {
            return total + curr.price * curr.quantity;
          }, 0)}
        </Typography>
      </Box>

      {/* {Object.keys(SubtotalData).map((key, index) => {
        return (
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            my={2.5}
          >
            <Typography
              sx={{
                color: key === "total" ? "#d9d9d9 !important" : undefined,
              }}
              textTransform="uppercase"
              variant="body1"
            >
              {key}
            </Typography>
            <Typography
              textTransform="uppercase"
              variant={key === "total" ? "subtitle1" : "body1"}
            >
              {SubtotalData[key]}
            </Typography>
          </Box>
        );
      })} */}
    </Box>
  );
};

export function ToastNotify({ alertState, setAlertState }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={alertState.open}
      autoHideDuration={10000}
      key={"top center"}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );
}

export function StyledButton({ children, ...props }) {
  return (
    <>
      <Button
        {...props}
        sx={{
          color: "#ffffff",
          background:
            "radial-gradient(145.24% 943.2% at 7.91% 50%, #1c85fe91 0%, #be4bc870 40%)",
          fontSize: "18px",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          textTransform: "capitalize",
          //   fontFamily: "Josefin Sans",
          borderRadius: "12px",
          width: props.width,
          "&.Mui-disabled": {
            color: "#979EA7",
          },
          "&:hover": {
            background:
              "radial-gradient(145.24% 943.2% at 7.91% 50%, #be4bc870 0%, #1c85fe91 40%)",
          },
        }}
      >
        {children}
      </Button>
    </>
  );
}

export function StyledText({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#000000",
          fontSize: "18px",
          //   fontFamily: "Josefin Sans",
          fontWeight: "500",
          mr: props.mr,
        }}
      >
        {children}
      </Box>
    </>
  );
}
export function StyledTitle({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#000000",
          fontSize: "40px",
          //   fontFamily: "Josefin Sans",
          fontWeight: "700",
          mr: props.mr,
        }}
      >
        {children}
      </Box>
    </>
  );
}

// const AntTab = styled((props) => <Tab disableRipple {...props} />)(
//   // {  console.log("Probs", props)}
//   ({ theme }) => ({
//     textTransform: "none",
//     minWidth: 0,
//     [theme.breakpoints.up("sm")]: {
//       minWidth: 0,
//     },
//     paddingRight: theme.spacing(5),
//     color: "#7A7C82",

//     // opacity: 0.5,

//     fontWeight: "bold",
//     fontFamily: "Open Sans",
//     "&:hover": {
//       color: "#7A7C82",
//       //   opacity: 1,
//     },
//     "&.Mui-selected": {
//       color: "white",
//       background: "linear-gradient(231.13deg, #08A5CF 14.71%, #2FEF64 87.56%)",
//       opacity: 1,
//       borderRadius: "5px",
//     },
//     "&.Mui-focusVisible": {
//       backgroundColor: "#7A7C82",
//     },
//   })
// );
export function StyledTabs({ children, AntTab, ...props }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ border: "2px solid #D2D4D6", borderRadius: "10px" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant={"scrollable"}
        scrollButtons={"auto"}
        sx={{
          "& .MuiTabs-indicator": {
            display: "none",
            // background: 'linear-gradient(267.4deg, #08A5CF 15.07%, #2FEF64 80.58%)',
          },
        }}
      >
        <AntTab label={props.lable} />
        <AntTab label={props.lable} />
      </Tabs>
    </Box>
  );
}
