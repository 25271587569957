import { createSlice } from "@reduxjs/toolkit";

export const nftImagesSlice = createSlice({
  name: "nftImg",
  initialState: {
    // nftImagesArray: [],
    tokenGatedArray: [],
  },
  reducers: {
    addNftImages: (state, action) => {
      state.nftImagesArray = action.payload;
    },
    addtokenGatedArray: (state, action) => {
      state.tokenGatedArray = action.payload;
    },
  },
});

export const { addNftImages, addtokenGatedArray } = nftImagesSlice.actions;

export default nftImagesSlice.reducer;
