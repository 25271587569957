import React, { useState, useContext } from "react";
// import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Container, Tab, Tabs } from "@mui/material";
import { AppContext } from "../../utils";

import {
  CartItem,
  ShippingAddress,
  ShippingMethod,
  Payment,
} from "../../Components/index";
import { useEffect } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const btnName = ["Cart", "Information", "Payment"];
const order = {
  discountCode: 0,
  country: "",
  email: "",
  walletAddress: "",
  firstName: "",
  lastName: "",
  company: "",
  address: "",
  addressDetail: "",
  city: "",
  province: "",
  postalCode: "",
  contactNo: "",
  payWith: "Credit Card",
  shippingMethod: "",
  shippingFee: "",
};
const BuyProduct = () => {
  // const cart = useSelector((store) => store.cart);
  const { account } = useContext(AppContext);
  const [tabbtnValue, setTabBtnValue] = React.useState(0);
  const [OrderData, setOrderData] = useState(order);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setOrderData({ ...OrderData, [name]: value });
  };

  // eslint-disable-next-line no-unused-vars
  const changeTabbtnValue = (event, newValue) => {
    setTabBtnValue(newValue);
  };

  const tabPanelNames = {
    0: (
      <CartItem
        value={tabbtnValue}
        setTabBtnValue={setTabBtnValue}
        handleChange={handleChange}
        account={account}
      />
    ),
    1: (
      <ShippingAddress
        value={tabbtnValue}
        setTabBtnValue={setTabBtnValue}
        handleChange={handleChange}
        OrderData={OrderData}
        setOrderData={setOrderData}
      />
    ),
    // 2: (
    //   <ShippingMethod
    //     value={tabbtnValue}
    //     setTabBtnValue={setTabBtnValue}
    //     handleChange={handleChange}
    //     OrderData={OrderData}
    //     setOrderData={setOrderData}
    //   />
    // ),
    2: (
      <Payment
        value={tabbtnValue}
        setTabBtnValue={setTabBtnValue}
        OrderData={OrderData}
        setOrderData={setOrderData}
        handleChange={handleChange}
      />
    ),
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box mb={15}>
      <Container
        sx={{
          pt: { xs: "30px", md: "85px" },
        }}
        maxWidth="lg"
      >
        <Tabs
          indicatorColor=""
          variant="scrollable"
          value={tabbtnValue}
          allowScrollButtonsMobile
          //   aria-label="scrollable force tabs example"
          onChange={changeTabbtnValue}
          sx={{
            "& .MuiTabs-indicator": {
              background:
                "linear-gradient(267.4deg, #08A5CF 15.07%, #2FEF64 80.58%)",
            },
            "& .MuiTabs-scrollButtons": {
              "& .MuiSvgIcon-root": {
                fontSize: "2rem",
              },
            },
          }}
        >
          {btnName.map((name, index) => {
            return (
              <Tab
                key={index}
                disabled={
                  index === 1 && tabbtnValue === 0
                    ? true
                    : index === 2 && tabbtnValue === 0
                    ? true
                    : index === 3 && tabbtnValue === 0
                    ? true
                    : index === 3 && tabbtnValue === 1
                    ? true
                    : index === 3 && tabbtnValue === 2
                    ? true
                    : index === 2 && tabbtnValue === 1
                    ? true
                    : false
                }
                label={name}
                {...a11yProps(index)}
                sx={{
                  fontFamily: "'Open Sans'",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "14px",
                  textTransform: "uppercase",
                  mr: "15px",
                  background:
                    "linear-gradient(267.4deg, #dcdcdc 15.07%, #dcdcdc 80.58%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                  "&.Mui-selected": {
                    background:
                      "linear-gradient(267.4deg, #08A5CF 15.07%, #2FEF64 80.58%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                  },
                }}
              />
            );
          })}
        </Tabs>
        {Object.keys(tabPanelNames).map((key) => {
          return (
            <TabPanel value={tabbtnValue} index={parseInt(key)} key={key}>
              {tabPanelNames[key]}
            </TabPanel>
          );
        })}
      </Container>
    </Box>
  );
};

export default BuyProduct;
