import React from "react";
import { useGetAllWeb2ProductDataQuery } from "../services/web2ProductsApi";
import Loading from "./SmallComponents/loading";
import { Link } from "react-router-dom";

import { Box, Grid, Typography } from "@mui/material";

const UnFeaturedWeb2Product = () => {
  const { data, isLoading, isFetching } = useGetAllWeb2ProductDataQuery();
  return (
    <>
      <Loading loading={isLoading} />
      <Loading loading={isFetching} />

      <Grid container spacing={7}>
        {data?.unFeaturedProducts?.length > 0 &&
          data?.unFeaturedProducts?.map(
            ({ images, name, price, description, _id }, i) => {
              return (
                <Grid item xs={12} md={4} key={i}>
                  <Link
                    to={`/products/productdetail/${_id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      sx={{
                        height: "588px",
                        color: "#dcdcdc",
                        borderRadius: "18px",
                        border: "1px transparent",
                        backgroundImage:
                          " linear-gradient(#121B25, #121B25  ),linear-gradient(37.34deg, rgba(72, 77, 82, 0.75) 4.36%, rgba(142, 146, 151, 0.75) 48.4%, rgba(111, 123, 136, 0.75) 96.11%)",
                        backgroundOrigin: "border-box",
                        backgroundClip: "content-box, border-box",
                        padding: "2px",
                        "&:hover": {
                          backgroundImage:
                            " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
                        },
                      }}
                    >
                      <Box p={2}>
                        <img
                          src={`${images[0]}`}
                          style={{
                            width: "100%",
                            height: "375px",
                            borderRadius: "10px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mt={1}
                          mb={2}
                        >
                          <Typography
                            variant="h2"
                            sx={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {name}
                          </Typography>
                          <Box
                            sx={{
                              width: "180px",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Typography variant="subtitle2">
                              $ {price}
                            </Typography>
                          </Box>
                        </Box>

                        <Typography
                          variant="subtypo"
                          sx={{
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {description}
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
              );
            }
          )}
      </Grid>
      {data?.featuredProducts?.length === 0 &&
      data?.unFeaturedProducts?.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="200px"
        >
          <Typography textAlign="center">No products added yet</Typography>
        </Box>
      ) : null}
    </>
  );
};

export default UnFeaturedWeb2Product;
