import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import { gradientButton } from "../../Components/SmallComponents/AppComponents";
import { useState } from "react";
import { useGetSingleWeb2ProductQuery } from "../../services/web2ProductsApi";

import Loading from "../../Components/SmallComponents/loading";
import { addProduct } from "../../slice/slice";
import { useDispatch } from "react-redux";

import { BackButton } from "../../Components/SmallComponents/BackButton";
import { useCustmizedImagesApiMutation } from "../../services/custmizedImages";
import { ToastNotify } from "../../Components/SmallComponents/AppComponents";
// import finalPropsSelectorFactory from "react-redux/es/connect/selectorFactory";

export default function DetailProduct() {
  const { id } = useParams();
  const [counter, setCounter] = useState(1);
  const [custmizedImages] = useCustmizedImagesApiMutation();
  const { data = [], isFetching, isLoading } = useGetSingleWeb2ProductQuery(id);
  const matches = useMediaQuery("(max-width:960px)");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(data?.data?.images[0]);
  const [singleBaseUrl, setSingleBaseUrl] = useState("");
  const [singleImg, setSingleImg] = useState("");
  const [multipalImg, setMultipalImg] = useState([]);
  const [multipalBaseURL, setMultipalBaseURL] = useState([]);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const toastShowing = (displayMessage) => {
    setAlertState({
      open: true,
      message: displayMessage,
      severity: "error",
    });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addToCartProduct = async (status) => {
    try {
      if (
        // !data?.data?.heatPress &&
        // !data?.data?.embossing &&
        // !data?.data?.custom
        singleImg?.length === 0 &&
        multipalImg?.length === 0
      ) {
        dispatch(
          addProduct({
            ...data?.data,
            quantity: counter,
            imgId: "",
          })
        );
      } else {
        // if (!singleImg && multipalImg.length === 0) {
        //   return toastShowing("Please upload an image");
        // }

        // if (multipalImg.length > 0 && multipalImg.length < 2) {
        //   return toastShowing("Please upload an image");
        // }
        const formData = new FormData();
        formData.append("data", JSON.stringify({ productId: id }));
        if (singleImg) {
          formData.append("file", singleImg);
        } else {
          // eslint-disable-next-line array-callback-return
          multipalImg.map((info) => {
            formData.append("file", info);
          });
        }
        setLoading(true);
        const { data: dataS, error } = await custmizedImages(formData);
        if (error) {
          setLoading(false);
          return setAlertState({
            open: true,
            message: "Please, again upload image",
            severity: "error",
          });
        }
        dispatch(
          addProduct({
            ...data?.data,
            quantity: counter,
            imgId: dataS?.data ?? "",
          })
        );
        setLoading(false);
      }

      if (status) {
        navigate("/cart");
      }
      if (!status) {
        setAlertState({
          open: true,
          message: "Item added to your cart!",
          severity: "success",
        });
        setSingleBaseUrl("");
        setSingleImg("");
        setMultipalImg([]);
        setMultipalBaseURL([]);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // const incrementQuantity = () => {
  //   if (counter < data?.data?.totalQuantity || data?.data?.unLimitedStock) {
  //     setCounter(counter + 1);
  //   }
  // };
  // const decrementQuantity = () => {
  //   if (counter === 1) {
  //     setCounter(1);
  //   } else {
  //     setCounter(counter - 1);
  //   }
  // };

  const boxArray = data?.data?.images;
  const imgHover = (index) => {
    setImage(index);
  };
  useEffect(() => {
    imgHover();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSingleFile = async (event) => {
    const actualSize = 2 * 1024 * 1024;
    if (event.target.files[0]?.size > actualSize) {
      if (event?.target?.value) {
        event.target.value = "";
      }
      toastShowing(
        "Sorry, your image exceeds the maximum size allowed. Please upload an image that is smaller than 2 mb."
      );
    } else {
      let file = event.target.files[0];
      setSingleImg(file);
      const urlFILE = URL.createObjectURL(file);
      setSingleBaseUrl(urlFILE);
    }
  };

  const handleMultipulFile = async (event, i) => {
    const actualSize = 2 * 1024 * 1024;
    if (event.target.files[0]?.size > actualSize) {
      if (event?.target?.value) {
        event.target.value = "";
      }
      toastShowing(
        "Sorry, your image exceeds the maximum size allowed. Please upload an image that is smaller than 2 mb."
      );
    } else {
      [...event.target.files].forEach((file) => {
        const updateBaseUrl = [...multipalBaseURL];
        updateBaseUrl[i] = URL.createObjectURL(file);
        console.log(updateBaseUrl);
        // if (!updateBaseUrl[0]) {
        //   updateBaseUrl[0] = URL.createObjectURL(file);
        //   updateBaseUrl[1] = null;
        // }

        setMultipalBaseURL(updateBaseUrl);
        const updateMultipalImg = [...multipalImg];
        updateMultipalImg[i] = file;
        setMultipalImg(updateMultipalImg);
      });
    }
  };
  return (
    <>
      <Loading loading={isLoading} />
      <Loading loading={isFetching} />
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Box py={6} mb={10}>
        <Container maxWidth="lg">
          <Box pb={4}>
            <BackButton />
          </Box>
          <Grid container spacing={7}>
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  height: matches ? "400px" : "568px",
                  borderRadius: "18px",
                  border: "1px transparent",
                  backgroundImage:
                    " linear-gradient(#121B25, #121B25  ),linear-gradient(37.34deg, rgba(72, 77, 82, 0.75) 4.36%, rgba(142, 146, 151, 0.75) 48.4%, rgba(111, 123, 136, 0.75) 96.11%)",
                  backgroundOrigin: "border-box",
                  backgroundClip: "content-box, border-box",
                  padding: "2px",
                  "&:hover": {
                    backgroundImage:
                      " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
                  },
                }}
              >
                <img
                  src={image ? `${image}` : `${data?.data?.images[0]}`}
                  width="100%"
                  height="100%"
                  style={{
                    padding: "10px",
                    borderRadius: "24px",
                    objectFit: "cover",
                  }}
                  alt=""
                />
              </Box>

              <Box mt={4} display="flex" justifyContent="space-around">
                {boxArray?.map((item, index) => {
                  return (
                    <Box key={index}>
                      <img
                        src={`${item}`}
                        alt=""
                        width="80px"
                        height="80px"
                        style={{ borderRadius: "6px", objectFit: "cover" }}
                        onMouseOver={() => imgHover(item)}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography variant="h1" mt={4}>
                {data?.data?.name}
              </Typography>
              <Box display="flex" mt={4} alignItems="center">
                <Typography variant="subtitle3">
                  $ {data?.data?.price}
                </Typography>
                {/* <Typography variant="body1" ml={2}>
                  {data?.data?.discount > 0 ? (
                    <s>{data?.data?.discount}</s>
                  ) : null}
                </Typography> */}
              </Box>
              <Box
                mt={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {/* <Box
                  display="flex"
                  width={matches ? "150px" : "200px"}
                  height={"60px"}
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    borderRadius: "18px",
                    border: "1px transparent",
                    backgroundImage:
                      " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box, border-box",
                    padding: "2px",
                    "&:hover": {
                      backgroundImage:
                        " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
                    },
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Box
                      mx={1}
                      onClick={decrementQuantity}
                      sx={{
                        background: "#19C59F",
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                      }}
                    >
                      <RemoveIcon sx={{ color: "#000", cursor: "pointer" }} />
                    </Box>
                    <Typography mx={2}>{counter}</Typography>
                    <Box
                      mx={1}
                      mr={2}
                      onClick={incrementQuantity}
                      sx={{
                        background: "#19C59F",
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                      }}
                    >
                      <AddIcon sx={{ color: "#000", cursor: "pointer" }} />
                    </Box>
                  </Box>
                </Box> */}

                {data?.data?.unLimitedStock === false &&
                  data?.data?.totalQuantity > 0 && (
                    <Typography variant="h2">
                      Total Quantity : {data?.data?.totalQuantity}
                    </Typography>
                  )}
              </Box>
              {data?.data?.totalQuantity === 0 &&
                data?.data?.unLimitedStock === false && (
                  <Typography variant="h2" mt={"20px"}>
                    This product is out of stock.
                  </Typography>
                )}

              {data?.data?.totalQuantity === 0 &&
              data?.data?.unLimitedStock === false ? null : data?.data
                  ?.heatPress === false &&
                data?.data?.embossing === false &&
                data?.data?.custom === false ? null : (
                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "60px",
                    width: "100%",
                    borderRadius: "18px",
                    border: "1px transparent",
                    backgroundImage:
                      " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
                    // " linear-gradient(#121B25, #121B25  ),linear-gradient(37.34deg, rgba(72, 77, 82, 0.75) 4.36%, rgba(142, 146, 151, 0.75) 48.4%, rgba(111, 123, 136, 0.75) 96.11%)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box, border-box",
                    padding: "2px",
                    "&:hover": {
                      backgroundImage:
                        " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
                    },
                  }}
                >
                  <Box ml={2} display="flex" alignItems="center">
                    <Checkbox
                      checked
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                      sx={{
                        color: "rgba(217, 217, 217, 0.69)",
                        "&.Mui-checked": {
                          color: "#15BDAC",
                        },
                      }}
                    />
                    <Typography variant="body1" ml={1}>
                      {data?.data?.heatPress
                        ? "Heatpress"
                        : data?.data?.embossing
                        ? "Embossing"
                        : "Custom"}
                    </Typography>
                  </Box>
                </Box>
              )}
              {data?.data?.totalQuantity === 0 &&
              data?.data?.unLimitedStock === false ? null : (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection={matches ? "column" : "row"}
                  mt={3}
                >
                  {data?.data?.heatPress ? (
                    <Box
                      sx={{
                        width: matches ? "100%" : "48%",
                      }}
                    >
                      <Box display="flex" justifyContent="space-between">
                        {data?.data?.heatPressType === "Front & Back Custom" ? (
                          ["Front", "Back"].map((name, index) => (
                            <Box
                              key={name}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#121B25",
                                width: "48%",
                                height: "140px",
                                border: "3px dashed rgba(82, 89, 96, 0.26)",
                                cursor: "pointer",
                                borderRadius: "18px",
                                overflow: "hidden",

                                // "&:hover": {
                                //   border: "3px dashed #FA1B1B",
                                // },
                              }}
                            >
                              <label
                                htmlFor={name}
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <input
                                  id={name}
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => handleMultipulFile(e, index)}
                                  style={{ display: "none" }}
                                />
                                {multipalBaseURL[index] ? (
                                  <img
                                    src={multipalBaseURL[index]}
                                    style={{
                                      height: "140px",
                                    }}
                                    alt=""
                                  />
                                ) : (
                                  <Typography mt={1}>{name}</Typography>
                                )}
                              </label>
                            </Box>
                          ))
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              justifyContent: "center",
                              background: "#121B25",
                              width: "48%",
                              height: "140px",
                              border: "3px dashed rgba(82, 89, 96, 0.26)",
                              cursor: "pointer",
                              borderRadius: "18px",
                              overflow: "hidden",
                              // "&:hover": {
                              //   border: "3px dashed #FA1B1B",
                              // },
                            }}
                          >
                            <label
                              htmlFor="oneSide"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <input
                                id="oneSide"
                                type="file"
                                accept="image/*"
                                onChange={handleSingleFile}
                                style={{ display: "none" }}
                              />
                              {singleBaseUrl ? (
                                <img
                                  src={singleBaseUrl}
                                  style={{
                                    height: "140px",
                                  }}
                                  alt=""
                                />
                              ) : (
                                <Typography mt={1}>Upload image</Typography>
                              )}
                            </label>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ) : null}
                  {data?.data?.custom ? (
                    <Box
                      sx={{
                        width: matches ? "100%" : "48%",
                      }}
                    >
                      <Box display="flex" justifyContent="space-between">
                        {data?.data?.customType === "Front & Back Custom" ? (
                          ["Front", "Back"].map((name, index) => (
                            <Box
                              key={name}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#121B25",
                                width: "48%",
                                height: "140px",
                                border: "3px dashed rgba(82, 89, 96, 0.26)",
                                cursor: "pointer",
                                borderRadius: "18px",
                                overflow: "hidden",

                                // "&:hover": {
                                //   border: "3px dashed #FA1B1B",
                                // },
                              }}
                            >
                              <label
                                htmlFor={name}
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <input
                                  id={name}
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => handleMultipulFile(e, index)}
                                  style={{ display: "none" }}
                                />
                                {multipalBaseURL[index] ? (
                                  <img
                                    src={multipalBaseURL[index]}
                                    style={{
                                      height: "140px",
                                    }}
                                    alt=""
                                  />
                                ) : (
                                  <Typography mt={1}>{name}</Typography>
                                )}
                              </label>
                            </Box>
                          ))
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              justifyContent: "center",
                              background: "#121B25",
                              width: "48%",
                              height: "140px",
                              border: "3px dashed rgba(82, 89, 96, 0.26)",
                              cursor: "pointer",
                              borderRadius: "18px",
                              overflow: "hidden",
                              // "&:hover": {
                              //   border: "3px dashed #FA1B1B",
                              // },
                            }}
                          >
                            <label
                              htmlFor="oneSide"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <input
                                id="oneSide"
                                type="file"
                                accept="image/*"
                                onChange={handleSingleFile}
                                style={{ display: "none" }}
                              />
                              {singleBaseUrl ? (
                                <img
                                  src={singleBaseUrl}
                                  style={{
                                    height: "140px",
                                  }}
                                  alt=""
                                />
                              ) : (
                                <Typography mt={1}>Upload image</Typography>
                              )}
                            </label>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ) : null}
                  {data?.data?.embossing ? (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      sx={{
                        width: matches ? "100%" : "48%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          background: "#121B25",
                          width: "48%",
                          height: "140px",
                          border: "3px dashed rgba(82, 89, 96, 0.26)",
                          cursor: "pointer",
                          borderRadius: "18px",
                          overflow: "hidden",
                          // "&:hover": {
                          //   border: "3px dashed #FA1B1B",
                          // },
                        }}
                      >
                        <label
                          htmlFor="oneSide"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <input
                            id="oneSide"
                            type="file"
                            accept="image/*"
                            onChange={handleSingleFile}
                            style={{ display: "none" }}
                          />
                          {singleBaseUrl ? (
                            <img
                              src={singleBaseUrl}
                              style={{
                                height: "140px",
                              }}
                              alt=""
                            />
                          ) : (
                            <Typography mt={1}>Upload image</Typography>
                          )}
                        </label>
                      </Box>
                    </Box>
                  ) : null}
                  {/* <Box
                  sx={{
                    width: matches ? "100%" : "48%",
                  }}
                >
                  <Box display="flex" justifyContent="space-between">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        background: "#121B25",
                        width: "48%",
                        height: "140px",
                        border: "3px dashed rgba(82, 89, 96, 0.26)",
                        cursor: "pointer",
                        borderRadius: "18px",
                        "&:hover": {
                          border: "3px dashed #FA1B1B",
                        },
                      }}
                    >
                      <img src={""} width="40px" alt="" />
                      <Typography mt={1}>Front</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        background: "#121B25",
                        width: "48%",
                        height: "140px",
                        border: "3px dashed rgba(82, 89, 96, 0.26)",
                        cursor: "pointer",
                        borderRadius: "18px",
                        "&:hover": {
                          border: "3px dashed #FA1B1B",
                        },
                      }}
                    >
                      <img src={""} width="40px" alt="" />
                      <Typography mt={1}>Back</Typography>
                    </Box>
                  </Box>
                </Box> */}
                  {/* <Box
                  sx={{
                    width: matches ? "100%" : "48%",
                  }}
                >
                  <Typography variant="body1" mt={matches ? 2 : 0}>
                    Info:
                  </Typography>
                  <Typography variant="subtypo" mt={1}>
                    Lörem ipsum semimorär åmarad viktiga för att vadöns
                    tasigförsamhet. Onar gågging ...
                  </Typography>
                </Box> */}
                </Box>
              )}
              {data?.data?.totalQuantity === 0 &&
              data?.data?.unLimitedStock === false ? null : data?.data
                  ?.heatPress === false &&
                data?.data?.embossing === false &&
                data?.data?.custom === false ? null : (
                <>
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Typography variant="body1" mt={matches ? 2 : 0}>
                      Info:
                    </Typography>
                    {(data?.data?.totalQuantity === 0 &&
                    data?.data?.unLimitedStock === false
                      ? null
                      : data?.data?.heatPress) || data?.data?.custom ? (
                      <Typography variant="subtypo" mt={1}>
                        • To avoid white edges, extend your design to the bleed
                        line – but keep text and images within the safety area.
                        <br />• Photographs don't work well.
                      </Typography>
                    ) : (
                      <Typography variant="subtypo" mt={1}>
                        • If you are planning of getting a patch – max size for
                        the size will be 2.5-in W * 1.25-in H.
                        <br />• If there are too many small lines, there is a
                        chance that the Deboss might not come out as intended.
                        <br />• Photographs don't work well.
                      </Typography>
                    )}
                  </Box>
                </>
              )}

              <Box display="flex" justifyContent="space-between" mt={4}>
                <Box
                  width="48%"
                  sx={{
                    ...gradientButton,
                    background:
                      data?.data?.totalQuantity === 0 &&
                      data?.data?.unLimitedStock === false
                        ? "grey"
                        : "",
                    "&:hover": {
                      background:
                        data?.data?.totalQuantity === 0 &&
                        data?.data?.unLimitedStock === false
                          ? "grey"
                          : "linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%), linear-gradient(#121B25, #121B25  )",
                    },
                  }}
                >
                  <Button
                    disabled={
                      data?.data?.totalQuantity === 0 &&
                      data?.data?.unLimitedStock === false
                    }
                    onClick={() => addToCartProduct(true)}
                    variant={
                      data?.data?.totalQuantity === 0 &&
                      data?.data?.unLimitedStock === false
                        ? ""
                        : "gradienttext"
                    }
                    sx={{ height: "61px", width: "100%", fontSize: "22px" }}
                  >
                    Buy Now
                  </Button>
                </Box>
                <Box
                  width="48%"
                  sx={{
                    ...gradientButton,
                    background:
                      data?.data?.totalQuantity === 0 &&
                      data?.data?.unLimitedStock === false
                        ? "grey"
                        : "",
                    "&:hover": {
                      background:
                        data?.data?.totalQuantity === 0 &&
                        data?.data?.unLimitedStock === false
                          ? "grey"
                          : "linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%), linear-gradient(#121B25, #121B25  )",
                    },
                  }}
                >
                  <Button
                    disabled={
                      data?.data?.totalQuantity === 0 &&
                      data?.data?.unLimitedStock === false
                    }
                    variant={
                      data?.data?.totalQuantity === 0 &&
                      data?.data?.unLimitedStock === false
                        ? ""
                        : "gradienttext"
                    }
                    onClick={() => {
                      addToCartProduct(false);
                    }}
                    sx={{
                      height: "61px",
                      width: "100%",
                      fontSize: "22px",
                    }}
                  >
                    ADD TO CART
                  </Button>
                </Box>
              </Box>
              <Typography variant="h2" mt={6} mb={2}>
                Description
              </Typography>
              <Typography variant="subtypo" mb={1}>
                {data?.data?.description}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
