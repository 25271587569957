import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Web3 from "web3";

import { visa, master } from "../Components/SmallComponents/Images";
import { CustomTextField } from "./CartItem";
import { useOrderApiMutation } from "../services/orderApi";

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { usePostPaymentMutation } from "../services/collectionApi";
import { AppContext } from "../utils";
import { formatUnits, parseUnits } from "@ethersproject/units";
import Loading from "./SmallComponents/loading";
import { clear, reset } from "../slice/slice";
import { ToastNotify, gradientButton } from "./SmallComponents/AppComponents";
import { useGetAllDiscountQuery } from "../services/discountApi";
import PaymentFail from "./paymentFail";
import { Country, State } from "country-state-city";

const cardImages = [visa, master];
const CARD_OPTIONS = {
  //  placeholder: 'card number',
  style: {
    base: {
      iconColor: "#c4f0ff",
      fontWeight: 500,
      fontFamily: "Open Sans",
      color: "rgba(217, 217, 217, 0.69)",
      fontSize: "18px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": {
        fontWeight: 700,
        fontFamily: "Open Sans",
        color: "#4C5057",
        textTransform: "uppercase",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "red",
    },
  },
};
const Payment = ({
  setTabBtnValue,
  value,
  OrderData,
  setOrderData,
  handleChange,
}) => {
  const [orderPlace] = useOrderApiMutation();
  const { data } = useGetAllDiscountQuery();
  const { account } = useContext(AppContext);
  const [disCountCodeValue, setDisCountCodeValue] = useState("");
  const [checkoutError, setCheckoutError] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [shippingFee, setShippingFee] = useState(OrderData.shippingFee);
  const [discountCodeNumber, setDiscountCodeNumber] = useState("");
  const [openModal, setOpenModal] = useState(false);
  let allDisCountCode = data?.data;

  const cart = useSelector((store) => store.cart);
  let totalAmounts = cart?.reduce((total, curr) => {
    return total + curr.price * curr.quantity;
  }, 0);

  let orderId = cart?.map((item) => {
    return {
      productId: item._id,
      noOfItem: item.quantity,
      imgId: item.imgId,
    };
  });

  let cartProductDetail = cart?.map((item) => {
    return {
      name: item.name,
      noOfItem: item.quantity,
      price: item.price,
    };
  });

  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [postPayment] = usePostPaymentMutation();
  const {
    email,
    country,
    firstName,
    lastName,
    company,
    address,
    addressDetail,
    city,
    province,
    postalCode,
    contactNo,
    payWith,
    shippingMethod,
  } = OrderData;

  const [isProcessing, setProcessingTo] = useState(false);

  const [totalResult, setTotalResult] = useState({
    totalAmount: totalAmounts,
    discountAmount: 0,
    finalAmount: totalAmounts + OrderData?.shippingFee,
  });

  const [loading, setLoading] = useState(false);
  const [walletUsdBalance, setWalletUsdBalance] = useState("");
  const [ethToUsd, setEthToUSD] = useState("");
  const [fullName, setFullName] = useState({
    countryFullName: "",
    stateFullName: "",
  });
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  // error toast function
  const toastShowing = (displayMessage) => {
    setAlertState({
      open: true,
      message: displayMessage,
      severity: "error",
    });
  };

  const handleCardDetailsChange = (event) => {
    setDisabled(event.empty);
    event.error ? setCheckoutError(event.error.message) : setCheckoutError();
  };
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!checkoutError) {
      setProcessingTo(true);
      setLoading(true);
      const cardElement = elements.getElement(CardNumberElement);
      if (!stripe && !cardElement) {
        setProcessingTo(false);
        setOpenModal(true); // Navigate("/paymentFail", {
        //   state: "Your Stripe Publishable key issue Or Card Element Issue",
        // });
      } else {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: firstName,
            address: {
              line1: address,
              postal_code: postalCode,
              city: city,
              state: province,
            },
            phone: contactNo,
            email: email,
          },
        });
        if (!error) {
          try {
            const { id, card } = paymentMethod;
            const { data } = await postPayment({
              amount: totalResult.finalAmount,
              paymentId: id,
            });

            // const response = await axios.post(`${url}/paymentRouter/postPayment`, {amount: amountUSD,payMethod: id,userWallet: line1, name, email,});
            if (data?.status) {
              // setProcessingTo(false);
              const orderData = {
                products: orderId,
                email,
                walletAddress: account,
                country: fullName?.countryFullName,
                firstName,
                lastName,
                company,
                address,
                addressDetail,
                city,
                province: fullName?.stateFullName,
                postalCode,
                contactNo,
                shippingMethod: OrderData?.shippingFee,
                payWith: "Credit Card",
                cartDetail: cartProductDetail,
                discountCode: discountCodeNumber,
                subTotal: totalAmounts,
                discountAmount: totalResult.discountAmount,
                amount: totalResult.finalAmount,
                paymentDetail: data?.data,
                cardLastFour: card?.last4,
                cardExpDate: `${card?.exp_month}/${card?.exp_year}`,
              };
              const { data: dataOrder } = await orderPlace(orderData);

              if (dataOrder.status) {
                dispatch(reset());
                dispatch(clear());
              }
              setLoading(false);

              Navigate("/cheackout_success", {
                state: data.message,
              });
            } else {
              setProcessingTo(false);
              setLoading(false);
              setOpenModal(true);
              // Navigate("/paymentFail", {
              //   state: error.status,
              // });
            }
          } catch (err) {
            setLoading(false);
            toastShowing(
              "Error in Payment process try Again add product in cart",
              err
            );
          }
        } else {
          setLoading(false);
          setProcessingTo(false);
          toastShowing(error.message);
        }
      }
    } else {
      setLoading(false);
      toastShowing(checkoutError);
    }
  };
  //get discount
  const getDiscount = (discount, discountNumber) => {
    let shippingPlusamounts = totalAmounts + +shippingFee;
    const discountAmounts = (shippingPlusamounts * discount) / 100;
    const finalAmount = totalAmounts - +discountAmounts + +shippingFee;
    setTotalResult({
      totalAmount: totalAmounts,
      discountAmount: discountAmounts,
      finalAmount: finalAmount,
    });
    setDiscountCodeNumber(discountNumber);
  };
  const removeDiscount = () => {
    setDisCountCodeValue("");
    setDiscountCodeNumber("");
    setTotalResult({
      totalAmount: totalAmounts,
      discountAmount: 0,
      finalAmount: totalAmounts + +shippingFee,
    });
  };

  const web3 = new Web3(
    Web3.givenProvider
      ? Web3.givenProvider
      : "https://mainnet.infura.io/v3/36fc2665f5504bc18c3b7f9fab0e0c46"
  );

  // const web3 = new Web3(
  //   Web3.givenProvider
  //     ? Web3.givenProvider
  //     : "https://data-seed-prebsc-2-s2.binance.org:8545"
  // );
  const init = useCallback(async () => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
      );
      const data = await response.json();
      let etherCurrentPrice = data?.ethereum?.usd;

      const balance = await web3.eth.getBalance(account);

      setWalletUsdBalance(etherCurrentPrice * +formatUnits(balance.toString()));
      setEthToUSD(+etherCurrentPrice);
    } catch (error) {
      console.log(error);
    }
  }, [account, web3.eth]);

  const transectionHandler = async (e) => {
    e.preventDefault();
    if (!account) {
        return toastShowing("Please connect your wallet to continue");
      }
    if (account && walletUsdBalance > totalResult.finalAmount) {
      try {
        let etherAmt = parseFloat(totalResult.finalAmount / ethToUsd).toFixed(
          18
        );

        setLoading(true);
        let add = "0x5999d8aB90A1C460fB63fbA06bbBbe3D6aF64183";
        // let add = "0xefCCE1F35Ab22Bb7Fd583A9b7F5070cBe1d822D4";

        const amountToSend = parseUnits(etherAmt.toString());
        const transactionComplete = await web3.eth.sendTransaction({
          from: account,
          to: add,
          value: amountToSend.toString(),
        });

        if (transactionComplete?.status) {
          const orderData = {
            products: orderId,
            email,
            walletAddress: account,
            country: fullName?.countryFullName,
            firstName,
            lastName,
            company,
            address,
            addressDetail,
            city,
            province: fullName?.stateFullName,
            postalCode,
            contactNo,
            cartDetail: cartProductDetail,
            shippingMethod: OrderData?.shippingFee,
            payWith: "Crypto",
            discountCode: discountCodeNumber,
            subTotal: totalAmounts,
            discountAmount: totalResult.discountAmount,
            amount: totalResult.finalAmount,
            web3TransactionHash: transactionComplete.transactionHash,
          };
          const { data: dataOrder } = await orderPlace(orderData);

          if (dataOrder.status) {
            dispatch(reset());
            dispatch(clear());
          }
        }
        setLoading(false);
        Navigate("/cheackout_success", {
          state:
            "Your order has been placed successfully. A confirmation email will be sent to you shortly.",
        });
      } catch (error) {
        setLoading(false);
        setOpenModal(true);
        // Navigate("/paymentFail", {
        //   state: "Transaction Failed.",
        // });
      }
    } else {
      toastShowing("You don't have enough balance to pay.");
    }
  };
  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (country || province) {
      let countryFullName = Country.getCountryByCode(country)?.name;

      let stateFullName = State.getStateByCodeAndCountry(
        province,
        country
      )?.name;
      setFullName({
        countryFullName: countryFullName,
        stateFullName: stateFullName,
      });
    }
  }, [country, province]);

  const boxStyle = {
    // background: "#121822",
    border: "3px solid rgba(82, 89, 96, 0.26)",
    borderRadius: "18px",
    padding: "20px",
    height: "62px",
    mt: 1.5,
    color: "#d9d9d9",
  };
  return (
    <>
      <Box>
        <ToastNotify alertState={alertState} setAlertState={setAlertState} />
        <Loading loading={loading} />
        <Typography
          variant="body1"
          sx={{
            color: "#d9d9d9 !important",
            letterSpacing: "0.03em",
            textTransform: "uppercase",
          }}
          mt={5}
        >
          Payment Method
        </Typography>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            gap: { xs: "0px", md: "20px" },
          }}
        >
          <Grid item md={7.5} xs={12} mt={3}>
            <Box
              sx={{
                background: "#121822",
                border: "3px solid rgba(82, 89, 96, 0.26)",
                borderRadius: "18px",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="22px"
                borderBottom="3px solid rgba(82, 89, 96, 0.26)"
              >
                <Box display="flex" alignItems="center" columnGap={"16px"}>
                  <Checkbox
                    icon={
                      <RadioButtonUnchecked
                        sx={{
                          color: "rgba(217, 217, 217, 0.69)",
                        }}
                      />
                    }
                    checkedIcon={
                      <CheckCircle
                        sx={{
                          color: "#15BDAC",
                        }}
                      />
                    }
                    checked={payWith === "Credit Card"}
                    onChange={() =>
                      setOrderData({ ...OrderData, payWith: "Credit Card" })
                    }
                  />
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      color: "#D9D9D9",
                    }}
                  >
                    Credit Card
                  </Typography>
                </Box>
                <Box display="flex" columnGap={"8px"}>
                  {cardImages.map((img, index) => {
                    return (
                      <img
                        key={index}
                        src={img}
                        alt="imgaes"
                        style={{
                          width: "38px",
                          height: "24px",
                        }}
                      />
                    );
                  })}
                </Box>
              </Box>
              {payWith === "Credit Card" && (
                <>
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      width: "100%",
                      paddingLeft: "24px",
                      paddingRight: "24px",
                    }}
                  >
                    <Box sx={boxStyle}>
                      <CardNumberElement
                        options={CARD_OPTIONS}
                        onChange={handleCardDetailsChange}
                      />
                    </Box>
                    <Box sx={boxStyle}>
                      <CardExpiryElement
                        options={CARD_OPTIONS}
                        onChange={handleCardDetailsChange}
                      />
                    </Box>
                    <Box sx={{ ...boxStyle, mb: 1.5 }}>
                      <CardCvcElement
                        options={CARD_OPTIONS}
                        onChange={handleCardDetailsChange}
                      />
                    </Box>

                    <Box
                      sx={{
                        ...gradientButton,

                        opacity: disabled && 0.5,
                        cursor: disabled ? "not-allowed" : "pointer",

                        textAlign: "center",
                        width: {
                          xs: "100%",
                          md: "fit-content",
                        },
                      }}
                    >
                      <Button
                        type="submit"
                        variant={disabled ? "#fff" : "gradienttext"}
                        // disabled={disable}
                        disabled={!stripe || disabled || checkoutError}
                        sx={{
                          letterSpacing: "0.03em",
                          textAlign: "center",
                          fontSize: "18px",
                          py: "10px",
                          px: "60px",
                          "&.Mui-disabled": {
                            color: "#fff",
                            cursor: "not-allowed",
                            opacity: "0.9",
                          },
                        }}
                      >
                        {isProcessing ? (
                          <Loading loading={loading} />
                        ) : (
                          "Pay Now"
                        )}
                      </Button>
                    </Box>
                  </form>
                </>
              )}
              <Box>
                <Box
                  p="22px"
                  display="flex"
                  alignItems="center"
                  columnGap={"16px"}
                >
                  <Checkbox
                    icon={
                      <RadioButtonUnchecked
                        sx={{
                          color: "rgba(217, 217, 217, 0.69)",
                        }}
                      />
                    }
                    checkedIcon={
                      <CheckCircle
                        sx={{
                          color: "#15BDAC",
                        }}
                      />
                    }
                    checked={payWith === "Crypto"}
                    onChange={() =>
                      setOrderData({ ...OrderData, payWith: "Crypto" })
                    }
                  />
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      color: "#D9D9D9",
                    }}
                  >
                    crypto
                  </Typography>
                </Box>
                {payWith === "Crypto" && (
                  <form
                    onSubmit={transectionHandler}
                    style={{
                      paddingLeft: "24px",
                      paddingRight: "24px",
                    }}
                  >
                    <Box
                      ml={{ xs: "0px", md: "24px" }}
                      mb={1.5}
                      sx={{
                        ...gradientButton,
                        textAlign: "center",
                        width: { xs: "100%", md: "fit-content" },
                      }}
                    >
                      <Button
                        type="submit"
                        variant="gradienttext"
                        // onClick={transectionHandler}
                        sx={{
                          letterSpacing: "0.03em",
                          textAlign: "center",
                          fontSize: "18px",
                          py: "10px",
                          px: "60px",
                        }}
                      >
                        Pay Now
                      </Button>
                    </Box>
                  </form>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box
              sx={{
                boxSizing: "border-box",
                background: "#121B25",
                borderRadius: "18px",
                pt: { xs: 3, md: 3 },
                px: { xs: 1, md: 2 },
                pb: 2.5,
                mx: { xs: "5px", md: "0px" },
                mt: { xs: "30px", md: "0px" },
              }}
            >
              {cart?.length > 0 &&
                cart?.map(({ name, _id, quantity, price, images }, index) => {
                  return (
                    <Box
                      key={index}
                      display="flex"
                      //   flexDirection={{ xs: "column", md: "row" }}
                      gap={{ xs: "7px", md: "20px" }}
                      alignItems="center"
                      mb="20px"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "2px solid #4A4F56",
                          py: "2px",
                          px: "6px",
                          borderRadius: "6px",
                        }}
                      >
                        <img
                          src={`${images[0]}`}
                          style={{
                            width: "48px",
                            height: "60px",
                          }}
                          alt=" bag"
                        />
                      </Box>

                      <Box display="flex" flexGrow={1} flexDirection={"column"}>
                        <Typography
                          sx={{
                            display: "-webkit-box",
                            WebkitLineClamp: "1",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            color: "#d9d9d9",
                            fontSize: "14px !important",
                          }}
                        >
                          {name}
                        </Typography>
                        <Typography
                          mt={1}
                          letterSpacing="0.03em"
                          textTransform="uppercase"
                          color="rgba(217, 217, 217, 0.69)"
                          fontSize="14px !important"
                        >
                          Quantity:{quantity}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "180px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Typography variant="body1">$ {price}</Typography>
                      </Box>
                    </Box>
                  );
                })}
              <Box display="flex" justifyContent="space-between" my={2.5}>
                <Typography textTransform="uppercase" variant="body1">
                  Subtotal
                </Typography>
                <Typography textTransform="uppercase" variant="body1">
                  $ {totalResult.totalAmount}
                </Typography>
              </Box>

              {value && (
                <>
                  <Box display="flex" justifyContent="space-between">
                    <Typography textTransform="uppercase" variant="body1">
                      Discount
                    </Typography>

                    <Typography textTransform="uppercase" variant="body1">
                      $ {totalResult.discountAmount}
                    </Typography>
                  </Box>
                  <CustomTextField
                    placeholder="Discount Code"
                    disabled={discountCodeNumber ? true : false}
                    value={disCountCodeValue}
                    onChange={(e) => setDisCountCodeValue(e.target.value)}
                    fullWidth
                    sx={{
                      mt: 2,
                      mb: 2,
                      textTransform: "uppercase",
                    }}
                  />
                  {/* //discountcode value matach all discount code  */}
                  {allDisCountCode?.map(
                    ({ _id, discountCode, discount, activeDiscount }) => {
                      if (
                        disCountCodeValue.toLowerCase() ===
                          discountCode.toLowerCase() &&
                        activeDiscount === true
                      ) {
                        return (
                          <>
                            <Typography fontSize={"14px !important"}>
                              {/* <span style={{ color: "green" }}>Valid</span> */}
                              You get {discount} % off.
                            </Typography>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              my={2}
                            >
                              <Box
                                sx={{
                                  ...gradientButton,
                                }}
                              >
                                <Button
                                  variant="gradienttext"
                                  onClick={() => {
                                    getDiscount(discount, discountCode);
                                  }}
                                >
                                  Get Discount
                                </Button>
                              </Box>
                              <Box
                                sx={{
                                  ...gradientButton,
                                }}
                              >
                                <Button
                                  variant="gradienttext"
                                  onClick={removeDiscount}
                                >
                                  Cancel
                                </Button>
                              </Box>
                            </Box>
                          </>
                        );
                      }
                    }
                  )}
                  {/* <Typography
            variant="subtitle1"
            sx={{
              fontSize: "14px !important",
              fontFamily: "Open Sans !important",
            }}
          >
            Connect your wallet.
          </Typography> */}
                </>
              )}

              <Box display="flex" mb={2.5} justifyContent="space-between">
                <Typography textTransform="uppercase" variant="body1">
                  Shipping
                </Typography>
                <Typography textTransform="uppercase" variant="body1">
                  $ {shippingFee}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography textTransform="uppercase" variant="body1">
                  Total
                </Typography>
                <Typography textTransform="uppercase" variant="subtitle1">
                  $ {totalResult.finalAmount.toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <PaymentFail setOpenModal={setOpenModal} openModal={openModal} />
    </>
  );
};

export default Payment;
