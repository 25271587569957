import { api } from "./api";

export const custmizedImages = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    custmizedImagesApi: builder.mutation({
      query: (body) => {
        return { url: "/custmize-images-upload", method: "POST", body: body };
      },
      invalidatesTags: [
        "getSingleCollection",
        "getSingleWeb3Product",
        "getSingleWeb2Product",
      ],
    }),
  }),
});
export const { useCustmizedImagesApiMutation } = custmizedImages;
