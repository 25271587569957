import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppContextProvider } from "./utils";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const PUBLIC_KEY =
  "pk_live_51HRGIEGSAAt2qVjaqcFZzB2zBEHI2n1TBqvFUrT7xhCxz5HM2gXziO76ybwhCvODvzwVJz6O8RPPWSAm3a2wYxrc00mwLzxsH5";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppContextProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Elements stripe={stripeTestPromise}>
              <App />
            </Elements>
          </BrowserRouter>
          <CssBaseline />
        </ThemeProvider>
      </AppContextProvider>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
