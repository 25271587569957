import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import { paymentFail } from "./SmallComponents/Images";
import { gradientButton } from "./SmallComponents/AppComponents";
import { useLocation, } from "react-router-dom";

export default function PaymentFail({openModal,setOpenModal}) {
  const location = useLocation();

  const status = location.state;

  // const navigate = useNavigate();

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiPaper-root": {
          background: "#121B25",
          borderRadius: "10px",
          width: { sm: "70%", md: "50%", xs: "100%" },
          minHeight: "300px",
          boxShadow: 2,
        },
      }}
    >
      <Box sx={{ p: 2, width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>
        <Box sx={{ width: "100px", height: "100px", mx: "auto" }}>
          <img
            src={paymentFail}
            alt=""
            srcSet=""
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </Box>
        <Typography
          id="alert-dialog-title"
          sx={{
            color: "white",
            textAlign: "center",
            fontSize: "20px",
            my: 2,
          }}
        >
          Payment Failed
        </Typography>

        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Open Sans",
            fontSize: "11px",
            width: "90%",
            mx: "auto",
            my: 1,
          }}
        >
          {status}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
            gap: "20px 10px",
          }}
        >
          <Box
            onClick={handleClose}
            sx={{
              ...gradientButton,
              // display: { xs: "none", md: "block" },
            }}
          >
            <Button
              variant="gradienttext"
              sx={{
                px: "14px",
                py: "7px",
                width: "200px",
              }}
            >
              Ok
            </Button>
          </Box>
          {/* <Box
            sx={{
              ...gradientButton,
              // display: { xs: "none", md: "block" },
            }}
          >
            <Button
              variant="gradienttext"
              sx={{
                px: "14px",
                py: "7px",
                width: "200px",
              }}
            >
              Try Again
            </Button>
          </Box> */}
        </Box>
      </Box>
    </Dialog>
  );
}
