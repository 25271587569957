import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
const array = [
  { title: "Product Name here", describe: "Product Name here" },
  {
    title: "Description",
    describe:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam in iusto quidem nobis quos et repellendus tenetur suscipit pariatur laborum, magni, accusantium veritatis distinctio id totam aperiam. Incidunt, minima quibusdam. Nam in iusto quidem nobis quos et repellendus tenetur suscipit pariatur laborum, magni, accusantium veritatis distinctio id totam aperiam. Incidunt, minima quibusdam.",
  },
  { title: "Customization Options", describe: "Customization Options" },
  { title: "In Stock", describe: "In Stock" },
  { title: "Price", describe: "$99.99" },
];

export const DetailCollection = () => {
  return (
    <Container maxWidth="lg">
      <Box
        py={7}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: { xs: "30px 0px", sm: "0px 20px" },
        }}
      >
        <Box sx={{ width: { xs: "0px", sm: "30%" } }}></Box>
        <Box sx={{ width: { xs: "100%", sm: "70%" }, mx: "auto" }}>
          <Grid container sx={{ gap: { xs: "20px 0px", sm: "0px 30px" } }}>
            {[0, 1].map((value) => (
              <Grid
                key={value}
                item
                xs={12}
                sm={5.5}
                md={4}
                lg={2.5}
                justifyContent="flex-start"
                alignItems="start"
                width="100%"
              >
                <Box
                  sx={{
                    height: { xs: "300px", sm: "200px", lg: "172px" },
                    width: "100%",
                  }}
                >
                  {" "}
                  <img
                    src="https://media.istockphoto.com/id/1193750118/photo/beautiful-asian-woman-carrying-colorful-bags-shopping-online-with-mobile-phone.jpg?s=612x612&w=0&k=20&c=j1SpSX7c3qzBrUT5f7HRoOfxQnPxZY_c6yb3AvXA5f8="
                    alt=""
                    srcSet=""
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />{" "}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box>
        {array.map((item, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "start",
                gap: "30px 20px",
                my: 1,
              }}
            >
              <Typography
                variant="subtypo4"
                sx={{ width: "30%", textAlign: "right",   color: "rgba(220, 220, 220, 0.69)", }}
              >
                {item.title}
              </Typography>
              <Typography
                variant="subtypo4"
                sx={{ width: "70%", textAlign: "left" }}
              >
                {item.describe}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Container>
  );
};
