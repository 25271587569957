import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query";
import { api as mainApi } from "../services/api";
import { addToCart } from "../slice/slice.js";
import dialogReducer from "../services/appDialogStates";
import nftImagesReducer from "../slice/nftImgSlice";
export const store = configureStore({
  reducer: {
    cart: addToCart.reducer,
    dialogState: dialogReducer,
    nftImages: nftImagesReducer,
    [mainApi.reducerPath]: mainApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      mainApi.middleware
    ),
});
setupListeners(store.dispatch);
