import React from "react";
import { Box, Container, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CollectCards from "./CollectCards";
import ProductCards from "./ProductCards";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      <Box py={10}>
        <Container maxWidth="lg">
          <Typography variant="h1" textAlign="center" mb={4}>
            Collections
          </Typography>
          <CollectCards />
          <Box display="flex" justifyContent="center" mt={4}>
            <Link to="/collection" style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  cursor: "pointer",
                  width: "200px",
                  height: "55px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "18px",
                  fontFamily: "Open Sans",
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#dcdcdc",
                  border: "1px transparent",
                  backgroundImage:
                    " linear-gradient(#121B25, #121B25  ),linear-gradient(37.34deg, rgba(72, 77, 82, 0.75) 4.36%, rgba(142, 146, 151, 0.75) 48.4%, rgba(111, 123, 136, 0.75) 96.11%)",
                  backgroundOrigin: "border-box",
                  backgroundClip: "content-box, border-box",
                  padding: "2px",
                  "&:hover": {
                    backgroundImage:
                      " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
                  },
                }}
              >
                VIEW ALL
                <ArrowForwardIcon
                  style={{ marginLeft: "10px", fontSize: "23px" }}
                />
              </Box>
            </Link>
          </Box>
          <Typography variant="h1" textAlign="center" mt={8} mb={4}>
            Products
          </Typography>
          <ProductCards  />
          <Box display="flex" justifyContent="center" mt={4}>
            <Link to="/products" style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  cursor: "pointer",
                  width: "200px",
                  height: "55px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "18px",
                  fontFamily: "Open Sans",
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#dcdcdc",
                  border: "1px transparent",
                  backgroundImage:
                    " linear-gradient(#121B25, #121B25  ),linear-gradient(37.34deg, rgba(72, 77, 82, 0.75) 4.36%, rgba(142, 146, 151, 0.75) 48.4%, rgba(111, 123, 136, 0.75) 96.11%)",
                  backgroundOrigin: "border-box",
                  backgroundClip: "content-box, border-box",
                  padding: "2px",
                  "&:hover": {
                    backgroundImage:
                      " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
                  },
                }}
              >
                VIEW ALL
                <ArrowForwardIcon
                  style={{ marginLeft: "10px", fontSize: "23px" }}
                />
              </Box>
            </Link>
          </Box>
        </Container>
      </Box>
    </>
  );
}
