import { api } from "./api";

export const discount = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAllDiscount: builder.query({
      query: () => {
        return {
          url: `/get-all-active-discounts`,
          method: "GET",
        };
      },
    }),
  }),
});
export const { useGetAllDiscountQuery } = discount;
