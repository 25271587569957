import { api } from "./api";

export const web2ProductApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAllWeb2ProductData: builder.query({
      query: () => {
        return { url: "/all-user-web2-products", method: "GET" };
      },
    }),
    getSingleWeb2Product: builder.query({
      query: (id) => {
        return {
          url: `/find-user-web2-product/${id}`,
          method: "GET",
        };
      },
      providesTags: ["getSingleWeb2Product"],
    }),
  }),
});
export const { useGetAllWeb2ProductDataQuery, useGetSingleWeb2ProductQuery } =
  web2ProductApi;
