import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { url } from "../utils";

export const api = createApi({
  reducerPath: "api",
  tagTypes: [
    "getSingleCollection",
    "getSingleWeb3Product",
    "getSingleWeb2Product",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: url,
  }),
  endpoints: () => ({}),
});
