import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import { collectionLockedImg } from "./SmallComponents/Images";
import { gradientButton } from "./SmallComponents/AppComponents";
import { useSelector, useDispatch } from "react-redux";
import { openCollectionLockedDialog } from "../services/appDialogStates";

export default function CollectionLockedDialog() {
  const dispatch = useDispatch();
  const state = useSelector(
    (state) => state.dialogState.collectionLockedDialog
  );

  const handleClose = () => {
    dispatch(
      openCollectionLockedDialog({
        open: false,
        message: "",
        contractAddress: "",
      })
    );
  };

  return (
    <Dialog
      open={state.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiPaper-root": {
          background: "#121B25",
          borderRadius: "10px",
          width: { sm: "70%", md: "50%", xs: "100%" },
          minHeight: "300px",
          boxShadow: 2,
        },
      }}
    >
      <Box sx={{ p: 2, width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>
        <Box sx={{ width: "100px", height: "100px", mx: "auto" }}>
          <img
            src={collectionLockedImg}
            alt=""
            srcSet=""
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </Box>
        <Typography
          id="alert-dialog-title"
          sx={{
            color: "white",
            fontFamily: "Anton",
            textAlign: "center",
            fontSize: "30px",
            letterSpacing: "0.03em",
            my: 2,
            textTransform: "uppercase",
          }}
        >
          Collection Locked
        </Typography>

        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Open Sans",
            fontSize: "11px",
            width: "90%",
            mx: "auto",
            my: 1,
          }}
        >
          {`These products can only be accessed by ${state.message} NFT holders.`}
        </Typography>

        <Box
          mt={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
            gap: "20px 10px",
          }}
        >
          <Box
            onClick={handleClose}
            sx={{
              ...gradientButton,
              // display: { xs: "none", md: "block" },
            }}
          >
            <Button
              variant="gradienttext"
              sx={{
                px: "14px",
                py: "7px",
                width: "200px",
              }}
            >
              Unlock Later
            </Button>
          </Box>
          <a
            href={`https://opensea.io/assets/ethereum/${state.contractAddress}`}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Box
              sx={{
                ...gradientButton,
                // display: { xs: "none", md: "block" },
              }}
            >
              <Button
                variant="gradienttext"
                sx={{
                  px: "14px",
                  py: "7px",
                  width: "200px",
                }}
              >
                Unlock Now
              </Button>
            </Box>
          </a>
        </Box>
      </Box>
    </Dialog>
  );
}
