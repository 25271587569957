import React, { useCallback, useContext, useState } from "react";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { BsDiscord, BsTwitter } from "react-icons/bs";
import { useEffect } from "react";
import { Contract } from "ethers";
import { useGetSingleCollectionQuery } from "../../services/collectionApi";

import Loading from "../../Components/SmallComponents/loading";
import Web3product from "../../Components/Web3product";
import { BackButton } from "../../Components/SmallComponents/BackButton";
import { AppContext } from "../../utils";
import tokenAbi from "../../ConnectivityAssets/tokenAbi.json";
import { ToastNotify } from "../../Components/SmallComponents/AppComponents";
import { lockimg } from "../../Components/SmallComponents/Images";

export default function AboutCollect() {
  const { account, signer } = useContext(AppContext);
  const params = useParams();
  const matches = useMediaQuery("(max-width:960px)");
  const [nftBalance, setNftBalance] = useState(0);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const {
    data = [],
    isLoading,
    isFetching,
  } = useGetSingleCollectionQuery(params?.id);

  const init = useCallback(async () => {
    try {
      const tokensArray = data?.collectionProduct?.tokenArray;
      if (account) {
        let totalNftBalance = 0;

        if (tokensArray?.length > 0) {
          for (let i = 0; i < tokensArray.length; i++) {
            let tokenAddress = tokensArray[i]?.tokenAddress;

            const TokenContract = new Contract(tokenAddress, tokenAbi, signer);

            const balance = await TokenContract.balanceOf(account);

            totalNftBalance = totalNftBalance + +balance;
          }
          setNftBalance(totalNftBalance);
        }
      }
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, data?.collectionProduct?.tokenArray, signer]);

  useEffect(() => {
    window.scrollTo(0, 0);
    init();
  }, [init]);
  return (
    <>
      <Loading loading={isLoading} />
      <Loading loading={isFetching} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Box mb={10}>
        <Box
          py={3}
          sx={{
            background: "#121B25",
          }}
        >
          <Container maxWidth="lg">
            <Box>
              <BackButton />
            </Box>
            <Box
              mt={3}
              display="flex"
              flexDirection={matches ? "column" : "row"}
            >
              <Box
                sx={{
                  width: matches ? "100%" : "333px",
                  height: matches ? "100%" : "176px",
                  borderRadius: "18px",
                  border: "1px transparent",
                  backgroundImage:
                    " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
                  backgroundOrigin: "border-box",
                  backgroundClip: "content-box, border-box",
                  padding: "2px",
                  position: "relative",
                }}
              >
                <img
                  src={`${data?.collectionProduct?.logo}`}
                  style={{
                    padding: "10px",
                    borderRadius: "24px",
                    objectFit: "cover",
                  }}
                  width="100%"
                  height="100%"
                  alt=""
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "12px",
                    right: "7px",
                    display:
                      data?.collectionProduct?.tokenArray?.length > 0 &&
                      nftBalance === 0
                        ? "block"
                        : "none",
                  }}
                >
                  <img src={lockimg} width="52px" alt="lock" />
                </Box>
              </Box>
              <Box
                mt={matches ? 2 : 1}
                ml={matches ? 0 : 2}
                display="flex"
                flexDirection="column"
                alignItems={matches ? "center" : "flex-start"}
              >
                <Typography variant="subtitle1">
                  {data?.collectionProduct?.featured === true
                    ? "Featured Collection"
                    : ""}
                </Typography>
                <Typography variant="h4" mt={1}>
                  {data?.collectionProduct?.name}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  textAlign="center"
                  gap="15px"
                  mt={1}
                >
                  <Link
                    to={`${data?.collectionProduct?.twitterLink}`}
                    target="_blank"
                    className="linkStyle"
                    style={{
                      textDecoration: "none",
                      color: "rgba(220, 220, 220, 0.69)",
                    }}
                  >
                    <BsTwitter
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                  <Link
                    to={`${data?.collectionProduct?.discordLink}`}
                    target="_blank"
                    className="linkStyle"
                    style={{
                      textDecoration: "none",
                      color: "rgba(220, 220, 220, 0.69)",
                    }}
                  >
                    <BsDiscord
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                </Box>

                <Typography variant="subtypo" width="100%">
                  {data?.collectionProduct?.description}
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box borderBottom="3px solid #262E34" width="100%" />
        <Container>
          <Box pt={3}>
            <Typography variant="h1" textAlign="center" mb={4}>
              {data?.collectionProduct?.products?.length > 0
                ? "Collection Products"
                : "No Products"}
            </Typography>
            <Box mt={4}>
              <Web3product data={data} nftBalance={nftBalance} />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
