import React, { useContext } from "react";
import { AppContext } from "../utils";
import { Close, Home, ShoppingCart, Apps } from "@mui/icons-material";
import { logo } from "./SmallComponents/Images";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  Box,
  Container,
} from "@mui/material";
import { NavLink, Link, Outlet, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

import { useDispatch, useSelector } from "react-redux";
import { gradientButton } from "./SmallComponents/AppComponents";
import { DeleteForever } from "@mui/icons-material";
import { BsFillBoxFill } from "react-icons/bs";
import { deleteProduct } from "../slice/slice";

const sideArray = [
  {
    name: "Home",
    to: "/",
    icon: (
      <Home
        sx={{
          fontSize: "25px",
        }}
      />
    ),
  },
  {
    name: "Collections",
    to: "/collection",
    icon: (
      <Apps
        sx={{
          fontSize: "25px",
        }}
      />
    ),
  },
  {
    name: "Products",
    to: "/products",
    icon: (
      <BsFillBoxFill
        style={{
          fontSize: "20px",

          marginLeft: "4px",
        }}
      />
    ),
  },
];

const textGraident = {
  color: "#DCDCDC",
  borderBottom: "3px solid transparent",
  borderImage: "linear-gradient(267.4deg, #08A5CF 15.07%, #2FEF64 80.58%)",
  borderImageSlice: "1",
  width: "max-content",
};
const navStyle = {
  textDecoration: "none",
  fontFamily: "Roboto sans-serif",
  fontWeight: 400,
  fontSize: "16px",
  color: "rgba(220, 220, 220, 0.69)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const Header = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const totalOrder = cart
    ?.map((info) => info.quantity)
    .reduce((a, b) => a + b, 0);
  const matches = useMediaQuery("(max-width:900px)");
  const { account, connect, disconnect } = useContext(AppContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElCart, setAnchorElCart] = React.useState(null);

  const handleOpenNavMenu = React.useCallback((event) => {
    setAnchorElNav(event.currentTarget);
  }, []);

  const handleCloseNavMenu = React.useCallback(() => {
    setAnchorElNav(null);
  }, []);

  const handleOpenNavCart = React.useCallback((event) => {
    setAnchorElCart(event.currentTarget);
  }, []);

  const handleCloseNavCart = React.useCallback(() => {
    setAnchorElCart(null);
  }, []);

  const removeItem = (id) => {
    dispatch(deleteProduct(id));
  };

  const connectWallet = () => {
    connect().then(() => {
      handleCloseNavMenu();
    });
  };

  return (
    <>
      <Box
        height={{ xs: "65px", md: "89px" }}
        backgroundColor="#121B25"
        display="flex"
        alignItems="center"
        py={3}
        borderBottom="3px solid #262E34"
      >
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center" backgroundColor="#121B25">
            <Link to="/">
              <Box mt={{ xs: 1, md: 0.5 }} marginRight={"30px"}>
                <img
                  src={logo}
                  style={{
                    width: matches ? "80px" : "95px",
                  }}
                  alt="logo"
                />
              </Box>
            </Link>

            {matches ? (
              <Box
                display={{ xs: "flex", md: "none" }}
                flexGrow={1}
                justifyContent={"flex-end"}
              >
                {anchorElNav === null ? (
                  <IconButton
                    size="large"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={handleOpenNavMenu}
                  >
                    <MenuIcon sx={{ color: "#fff", fontSize: "25px" }} />
                  </IconButton>
                ) : (
                  <IconButton
                    size="large"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={handleCloseNavMenu}
                  >
                    <Close sx={{ color: "#fff", fontSize: "25px" }} />
                  </IconButton>
                )}

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                    ml: "15px",
                    "& .MuiPaper-root": {
                      backgroundColor: "#121B25",
                      color: "#fff",
                      width: "60%",
                    },
                  }}
                >
                  {sideArray.map(({ name, to, icon }, i) => (
                    <MenuItem key={i} onClick={handleCloseNavMenu}>
                      <NavLink
                        to={to}
                        style={({ isActive }) => ({
                          ...navStyle,
                          // ...(isActive ? textGraident : undefined),
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        })}
                      >
                        <IconButton
                          sx={{
                            color: "#d9d9d9",
                          }}
                        >
                          {icon}
                        </IconButton>
                        <Typography ml={i === 2 ? "2px" : "0px"}>
                          {name}
                        </Typography>
                      </NavLink>
                    </MenuItem>
                  ))}
                  <Link to="/cart" style={{ textDecoration: "none" }}>
                    <IconButton
                      sx={{
                        ml: 2.3,
                        my: "5px",
                      }}
                    >
                      <Box position="relative">
                        {cart.length > 0 && (
                          <Box
                            position="absolute"
                            right="34px"
                            top="-8px"
                            sx={{
                              background: "#fff",
                              color: "#000",
                              fontSize: "12px",
                              fontWeight: "700",
                              borderRadius: "5PX",
                              height: "17px",
                              width: "17px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {totalOrder}
                          </Box>
                        )}
                        <Box display="flex" alignItems="center">
                          <ShoppingCart
                            sx={{
                              color: "rgba(220, 220, 220, 0.69)",
                              fontSize: "25px",
                            }}
                          />
                          <Typography ml={cart.length > 0 ? 1.5 : 0.7}>
                            Cart
                          </Typography>
                        </Box>
                      </Box>
                    </IconButton>
                  </Link>
                  <Box display="flex" justifyContent="center">
                    <Box
                      display="flex"
                      justifyContent="center"
                      sx={{
                        ...gradientButton,
                        width: "70%",
                        ml: "10px",
                      }}
                    >
                      {account ? (
                        <Button
                          variant="gradienttext"
                          onClick={disconnect}
                          sx={{
                            px: "20px",
                          }}
                        >
                          {account.slice(0, 4) + "..." + account.slice(-4)}
                        </Button>
                      ) : (
                        <Button
                          variant="gradienttext"
                          onClick={connectWallet}
                          sx={{
                            px: "20px",
                          }}
                        >
                          Connect Wallet
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Menu>
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                flexGrow={1}
                alignItems="center"
              >
                {sideArray?.map(({ name, to, icon }, i) => {
                  return (
                    <Box mr={{ md: 2.5, lg: 4 }} key={i}>
                      <NavLink
                        to={to}
                        style={({ isActive }) => ({
                          ...navStyle,
                          ...(isActive ? textGraident : undefined),
                        })}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          sx={{
                            "&:hover": {
                              "& .MuiTypography-root": {
                                color: "#d9d9d9",
                              },
                              "& .MuiIconButton-root": {
                                color: "#d9d9d9",
                              },
                            },
                          }}
                        >
                          <IconButton
                            disableFocusRipple
                            disableRipple
                            sx={{
                              color:
                                pathname === to
                                  ? " #dcdcdc"
                                  : " rgba(220, 220, 220, 0.38)",

                              marginTop: i === 2 ? "5px" : "0px",
                            }}
                          >
                            {icon}
                          </IconButton>
                          <Typography
                            sx={{
                              color:
                                pathname === to
                                  ? " #dcdcdc"
                                  : " rgba(220, 220, 220, 0.69)",
                            }}
                          >
                            {name}
                          </Typography>
                        </Box>
                      </NavLink>
                    </Box>
                  );
                })}
              </Box>
            )}

            <IconButton
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={handleOpenNavCart}
              sx={{
                mr: 1,
                display: { xs: "none", md: "flex" },
              }}
            >
              <Box position="relative">
                {cart.length > 0 && (
                  <Box
                    position="absolute"
                    right="-7px"
                    top="-10px"
                    sx={{
                      background: "#fff",
                      color: "#000",
                      fontSize: "12px",
                      fontWeight: "700",
                      borderRadius: "5PX",
                      height: "20px",
                      width: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {totalOrder}
                  </Box>
                )}
                <ShoppingCart
                  sx={{
                    color: "rgba(220, 220, 220, 0.69)",
                    fontSize: "30px",
                  }}
                />
              </Box>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElCart}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElCart)}
              onClose={handleCloseNavCart}
              sx={{
                display: { xs: "none", md: "block" },
                ml: "15px",
                mt: 2,

                "& .MuiPaper-root": {
                  background: "#121B25",
                  border: "3px solid rgba(82, 89, 96, 0.26)",
                  boxShadow: "0px 13px 30px 12px rgba(18, 18, 18, 0.33)",
                  borderRadius: "18px",
                  color: "#fff",
                  width: "390px",
                },
              }}
            >
              <Typography m={2} variant="h2" fontSize="20px" color="#d9d9d9">
                CART ({cart.length})
              </Typography>
              <Box
                sx={{
                  borderBottom: "3px solid rgba(82, 89, 96, 0.26)",
                  width: "100%",
                }}
              />
              <Box p={2} sx={{ height: "370px", overflowY: "auto" }}>
                {cart?.length > 0 ? (
                  cart?.map(
                    (
                      { name, _id, quantity, price, images, discountCode },
                      index
                    ) => {
                      return (
                        <Box
                          key={index}
                          display="flex"
                          //   flexDirection={{ xs: "column", md: "row" }}
                          gap={{ xs: "7px", md: "20px" }}
                          mb="20px"
                        >
                          <IconButton
                            onClick={() => removeItem(_id)}
                            sx={{
                              display: { xs: "none", md: "block" },
                            }}
                          >
                            <DeleteForever
                              sx={{
                                color: "#DB2929",
                                fontSize: "25px",
                              }}
                            />
                          </IconButton>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "2px solid #4A4F56",
                              py: "2px",
                              px: "6px",
                              borderRadius: "6px",
                            }}
                          >
                            <img
                              src={`${images[0] ? images[0] : ""}`}
                              style={{
                                width: "48px",
                                height: "60px",
                              }}
                              alt=" bag"
                            />
                          </Box>

                          <Box mt={1}>
                            <Typography
                              sx={{
                                display: "-webkit-box",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                color: "#d9d9d9",
                                fontSize: "14px !important",
                              }}
                            >
                              {name}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                color: "#d9d9d9 !important",
                                width: "max-content",
                                letterSpacing: "0.05em",
                              }}
                            >
                              ${price}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    }
                  )
                ) : (
                  <Typography textAlign={"center"} variant="h2" mt={5}>
                    Your cart is empty. Please add some items to continue.
                  </Typography>
                )}
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                m={2}
              >
                <Link to="/cart" style={{ textDecoration: "none" }}>
                  <Box
                    open={Boolean(anchorElCart)}
                    onClick={handleCloseNavCart}
                    sx={{
                      ...gradientButton,
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="gradienttext"
                      sx={{
                        px: "120px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      View Cart
                    </Button>
                  </Box>
                </Link>
              </Box>
            </Menu>

            {/* </Link> */}
            <Box
              sx={{
                ...gradientButton,
                cursor: "pointer",

                display: { xs: "none", md: "block" },
              }}
            >
              {account ? (
                <Button
                  variant="gradienttext"
                  onClick={() => disconnect()}
                  sx={{
                    px: "28px",
                    py: "7px",
                  }}
                >
                  {account.slice(0, 4) + "..." + account.slice(-4)}
                </Button>
              ) : (
                <Button
                  variant="gradienttext"
                  onClick={() => connect()}
                  sx={{
                    px: "14px",
                    py: "7px",
                  }}
                >
                  Connect Wallet
                </Button>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
      <Outlet />
    </>
  );
};

export default Header;
