import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import ProductCards from "./ProductCards";
import UnFeaturedWeb2Product from "../../Components/UnFeaturedWeb2Product";

export default function Products() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Box py={10}>
        <Container maxWidth="lg">
          <Typography variant="h1" textAlign="center" mb={4}>
            Products
          </Typography>
          <Box mb={4}>
            <ProductCards />
          </Box>
          <Box mb={4}>
            <UnFeaturedWeb2Product />
          </Box>
        </Container>
      </Box>
    </>
  );
}
