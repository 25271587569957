import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Grid, MenuItem, Select, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import { CustomTextField } from "./CartItem";
import { useSelector } from "react-redux";
import {
  ShippingCard,
  ToastNotify,
  gradientButton,
} from "./SmallComponents/AppComponents";

import { AppContext } from "../utils";

import { State, City, Country } from "country-state-city";

//select the canda ana amrical country

const CustomSelect = styled(Select)({
  background: "#121822",
  border: "3px solid rgba(82, 89, 96, 0.26)",
  borderRadius: "18px !important",
  padding: "0 20px",
  width: "100%",
  height: "62px",
  color: "#d9d9d9",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 0",
    "& .MuiOutlinedInput-input:focus": {
      border: "none",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiInputBase-input": {
    color: "rgba(217, 217, 217, 0.69)",
  },

  "& .MuiSelect-icon": {
    color: "rgba(220, 220, 220, 0.69)",
    fontSize: "2rem",
  },
});

export const inputObject = {
  mt: 2,
  width: "100%",
  textTransform: "uppercase",
  "& .MuiInputBase-input": {
    color: "rgba(217, 217, 217, 0.69)",
    fontSize: "16px",
    textTransform: "uppercase",
  },
};

const ShippingAddress = ({
  value,
  setTabBtnValue,
  handleChange,
  OrderData,
  setOrderData,
}) => {
  const { account } = useContext(AppContext);
  const { company, address, addressDetail } = OrderData;
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const [countryState, setCountryState] = useState(null);
  const [stateCities, setStateCities] = useState(null);
  const [countries, setCountries] = useState(null);

  // error toast function
  const toastShowing = (displayMessage) => {
    setAlertState({
      open: true,
      message: displayMessage,
      severity: "error",
    });
  };
  const inputFieldData = [
    {
      name: "company",
      placeholder: "Company (OPTIONAL)",
      type: "text",
      value: company,
    },
    {
      name: "address",
      placeholder: "ADDRESS",
      type: "text",
      value: address,
    },

    {
      name: "addressDetail",
      placeholder: "Apartment, Suite, etc (Optional)",
      type: "text",
      value: addressDetail,
    },
  ];

  const checkValues = () => {
    // eslint-disable-next-line no-useless-escape
    const validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (
      OrderData.country.trim() !== "" &&
      OrderData.email.trim() !== "" &&
      OrderData.firstName.trim() !== "" &&
      OrderData.lastName.trim() !== "" &&
      OrderData.address.trim() !== "" &&
      OrderData.city.trim() !== "" &&
      // OrderData.province.trim() !== "" &&
      OrderData.postalCode.trim() !== "" &&
      OrderData.contactNo.trim() !== ""
    ) {
      if (!validEmail.test(OrderData.email.trim())) {
        return toastShowing(`Please enter valid email address`);
      }
      setOrderData({
        walletAddress: account,
        ...OrderData,
      });

      setTabBtnValue(value + 1);
    } else if (
      OrderData.country.trim() === "" ||
      OrderData.email.trim() === "" ||
      OrderData.firstName.trim() === "" ||
      OrderData.lastName.trim() === "" ||
      OrderData.address.trim() === "" ||
      OrderData.city.trim() === "" ||
      // OrderData.province.trim() === "" ||
      OrderData.postalCode.trim() === "" ||
      OrderData.contactNo.trim() === ""
    ) {
      const checkrequiredField =
        OrderData.country.trim() === ""
          ? "Country"
          : OrderData.email.trim() === ""
          ? "Email"
          : OrderData.firstName.trim() === ""
          ? "First Name"
          : OrderData.lastName.trim() === ""
          ? "Last Name"
          : OrderData.address.trim() === ""
          ? "Address"
          : // : OrderData.province.trim() === ""
          // ? "Province"
          OrderData.city.trim() === ""
          ? "City"
          : OrderData.postalCode.trim() === ""
          ? "Postal Code"
          : OrderData.contactNo.trim() === ""
          ? "Contact No"
          : null;
      if (checkrequiredField) {
        toastShowing(`${checkrequiredField} is required`);
      }
    }
  };

  const calculateShippingFee = (cart, country) => {
    console.log(country,'country')
    let shippingRates = {
      "USA and Canada": {
        crossBody: 20,
        luggage: 30,
        combined: 40,
      },
      "World Wide": {
        crossBody: 35,
        luggage: 55,
        combined: 72.5,
      },
    };
  
    let totalShippingCost = 0;
  let totalShippingCosts = 0
    cart.forEach((item) => {
      let productName = item.name;
      console.log(productName)
      if (productName.endsWith("Cross Body ")) {
        console.log('cross body call')
        totalShippingCost += shippingRates["USA and Canada"].crossBody;
        totalShippingCosts += shippingRates["World Wide"].crossBody;
      } else if (productName.endsWith("Luggage")) {
        console.log('lugage call')
        totalShippingCost += shippingRates["USA and Canada"].luggage;
        totalShippingCosts += shippingRates["World Wide"].luggage;
      }
    });
  
    let hasCrossBody = cart.some((item) => item.name.endsWith("Cross Body "));
    let hasLuggage = cart.some((item) => item.name.endsWith("Luggage"));
  console.log(hasCrossBody,hasLuggage)
    if (hasCrossBody && hasLuggage) {
      totalShippingCost = shippingRates["USA and Canada"].combined;
      totalShippingCosts = shippingRates["World Wide"].combined;
    } 
  
    if (country === "US" || country === "CA") {
      console.log(totalShippingCost)
      return totalShippingCost;
    } else {
      console.log(totalShippingCosts , 'world wide')
      return totalShippingCosts;
    }
  };
  
  // Use the function within the useEffect hook
  const cart = useSelector((store) => store.cart);
  useEffect(() => {
    
    console.log(cart);
  
    if (OrderData?.country) {
      const shippingFee = calculateShippingFee(cart, OrderData.country);
      setOrderData({
        ...OrderData,
        shippingFee: shippingFee,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OrderData.country]);
  useMemo(() => {
    const states = State.getStatesOfCountry(OrderData.country);
    setCountryState(states);
  }, [OrderData.country]);

  useMemo(() => {
    const canadaCities = City.getCitiesOfState(
      OrderData.country,
      OrderData.province
    );
    setStateCities(canadaCities);
  }, [OrderData.country, OrderData.province]);

  useEffect(() => {
    const countryList = Country.getAllCountries();
    const filterCountries = countryList.filter(
      (countryList) => countryList.name !== "Russia"
    );
    setCountries(filterCountries);
  }, []);
  
  // const handleChangeCity = (event) => {
  //   setOrderData({ ...OrderData, city: event.target.value });
  // };
  // // const handleChangeProvince = (event) => {
  // //   setOrderData({ ...OrderData, province: event.target.value });
  // // };

  return (
    <Box>
      {" "}
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Typography
        variant="body1"
        sx={{
          color: "#d9d9d9 !important",
          letterSpacing: "0.03em",
          textTransform: "uppercase",
        }}
        mt={5}
      >
        Shipping Address
      </Typography>
      <Grid container columnSpacing={10}>
        <Grid item xs={12} md={7.5}>
          <Box mt={3}>
            <CustomSelect
              displayEmpty
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={OrderData.country}
              inputProps={{
                MenuProps: {
                  MenuListProps: {
                    sx: {
                      backgroundColor: "#121822",
                    },
                  },
                },
              }}
              onChange={(event) => {
                setOrderData({
                  ...OrderData,
                  country: event.target.value,
                  province: "",
                  city: "",
                });
              }}
            >
              <MenuItem value="" disabled sx={{ display: "none" }}>
                Country Region
              </MenuItem>
              {countries?.map(({ name, isoCode }, i) => {
                return (
                  <MenuItem
                    value={isoCode}
                    key={i}
                    sx={{
                      color: "rgba(217, 217, 217, 0.69)",
                      fontSize: "14px",
                    }}
                  >
                    {name}
                  </MenuItem>
                );
              })}
            </CustomSelect>
          </Box>
          <Box mt={1.5}>
            <CustomTextField
              fullWidth
              name="email"
              placeholder="email"
              value={OrderData.email}
              onChange={handleChange}
              type="email"
              sx={inputObject}
            />
          </Box>

          <Box display="flex" columnGap="20px" justifyContent="space-between">
            <CustomTextField
              fullWidth
              name="firstName"
              placeholder="First Name"
              value={OrderData.firstName}
              onChange={handleChange}
              type="text"
              sx={inputObject}
            />

            <CustomTextField
              fullWidth
              name="lastName"
              value={OrderData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              type="text"
              sx={inputObject}
            />
          </Box>

          {inputFieldData.map((item, index) => {
            return (
              <CustomTextField
                key={index}
                placeholder={item.placeholder}
                type={item.type}
                name={item.name}
                value={item.value}
                onChange={handleChange}
                sx={inputObject}
              />
            );
          })}
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            flexDirection={{
              xs: "column",
              sm: "row",
            }}
            justifyContent="space-between"
            gap="20px"
          >
            <CustomSelect
              displayEmpty
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={OrderData.province}
              inputProps={{
                MenuProps: {
                  MenuListProps: {
                    sx: {
                      backgroundColor: "#121822",
                    },
                  },
                },
              }}
              onChange={(e) => {
                setOrderData({
                  ...OrderData,
                  province: e.target.value,
                  city: "",
                });
              }}
            >
              <MenuItem value="" disabled sx={{ display: "none" }}>
                Province
              </MenuItem>
              {countryState?.map(({ name, isoCode }, i) => {
                return (
                  <MenuItem
                    key={i}
                    sx={{
                      color: "rgba(217, 217, 217, 0.69)",
                      fontSize: "14px",
                    }}
                    value={isoCode}
                  >
                    {name}
                  </MenuItem>
                );
              })}
            </CustomSelect>

            <CustomTextField
              fullWidth
              name="city"
              value={OrderData.city}
              onChange={handleChange}
              placeholder="City"
              type="text"
              sx={{
                width: "100%",
                textTransform: "uppercase",
                "& .MuiInputBase-input": {
                  color: "rgba(217, 217, 217, 0.69)",
                  fontSize: "16px",
                  textTransform: "uppercase",
                },
              }}
            />
            {/* <CustomSelect
              displayEmpty
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={OrderData.city}
              inputProps={{
                MenuProps: {
                  MenuListProps: {
                    sx: {
                      backgroundColor: "#121822",
                    },
                  },
                },
              }}
              onChange={(event) =>
                setOrderData({ ...OrderData, city: event.target.value })
              }
            >
              <MenuItem value="" disabled sx={{ display: "none" }}>
                City
              </MenuItem>
              {stateCities?.map(({ name }, i) => {
                return (
                  <MenuItem
                    key={i}
                    sx={{
                      color: "rgba(217, 217, 217, 0.69)",
                      fontSize: "14px",
                    }}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                );
              })}

            </CustomSelect> */}
          </Box>
          <Box display="flex" columnGap="20px" justifyContent="space-between">
            <CustomTextField
              fullWidth
              name="postalCode"
              placeholder="POSTAL CODE"
              value={OrderData.postalCode}
              onChange={handleChange}
              type="text"
              sx={inputObject}
            />
            <CustomTextField
              fullWidth
              name="contactNo"
              value={OrderData.contactNo}
              onChange={handleChange}
              placeholder="PHONE"
              type="text"
              sx={inputObject}
            />
          </Box>
          <Box
            display={{
              xs: "none",
              md: "flex",
            }}
            justifyContent="flex-end"
            mt="30px"
          >
            <Box
              sx={{
                ...gradientButton,
                "&:hover": {
                  background:
                    "linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%), linear-gradient(#121B25, #121B25  )",
                },
              }}
            >
              <Button
                variant="gradienttext"
                sx={{
                  py: "10px",
                  fontSize: "18px",
                  px: "40px",
                }}
                onClick={checkValues}
              >
                Continue to payment
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4.5}>
          <ShippingCard />
          <Box
            display={{ xs: "flex", md: "none" }}
            justifyContent="flex-end"
            mt="30px"
          >
            <Button
              variant="gradient"
              sx={{
                py: "10px",
                px: { xs: "20px", md: "40px" },
              }}
              onClick={checkValues}
            >
              Continue to shipping
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShippingAddress;
