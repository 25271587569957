import { api } from "./api";

export const collectionApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getCollectionData: builder.query({
      query: () => {
        return { url: "/all-user-collections", method: "GET" };
      },
    }),
    getSingleCollection: builder.query({
      query: (id) => {
        return {
          url: `/single-user-collection/${id}`,
          method: "GET",
        };
      },
      providesTags: ["getSingleCollection"],
    }),
    postPayment: builder.mutation({
      query: (requestData) => ({
        url: "/postPayment",
        method: "POST",
        body: requestData,
      }),
    }),
    getNftImages: builder.mutation({
      query: (requestData) => ({
        url: "/nft-images",
        method: "POST",
        body: requestData,
      }),
    }),
  }),
});
export const {
  useGetCollectionDataQuery,
  useGetSingleCollectionQuery,
  usePostPaymentMutation,
  useGetNftImagesMutation,
} = collectionApi;
