import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ftlogo } from "../../Components/SmallComponents/Images";

export default function Footer() {
  const matches = useMediaQuery("(max-width:960px)");

  return (
    <Box position="absolute" width="100%" bottom={0}>
      <Container maxWidth="lg">
        <Box
          py={2}
          sx={{
            borderTop: "3px solid #262E34",
          }}
        >
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={4}
              display="flex"
              justifyContent={matches ? "center" : "start"}
              alignItems="center"
            >
              <Typography variant="subtypo" mr={1}>
                Powered by:
              </Typography>
              <Link
                to="https://www.nalikes.com/"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <img src={ftlogo} width="83px" alt="" />
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="subtypo">©2023 Bugatti Group</Typography>
            </Grid>
            <Grid item xs={12} md={4} display="flex" justifyContent="center">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent={matches ? "center" : "end"}
                alignItems={matches ? "center" : "start"}
              >
                <Link
                  to="https://www.bugatticollections.com/pages/privacy-policy"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <Typography
                    variant="subtypo"
                    sx={{
                      "&:hover": {
                        borderBottom: "1px solid white",
                        color: "white",
                      },
                    }}
                  >
                    Privacy Policy
                  </Typography>
                </Link>{" "}
                <Link
                  to="https://www.bugatticollections.com/pages/terms-and-conditions"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <Typography
                    variant="subtypo"
                    sx={{
                      "&:hover": {
                        borderBottom: "1px solid white",
                        color: "white",
                      },
                    }}
                  >
                    Terms & Conditions
                  </Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
